import ScrollToTopOnMount from "./ScrollToTopOnMount";

const Terms = () => <div>
    <ScrollToTopOnMount />
    <div className="container m-top csdk-pd">
        <h2 className="mb-5">Ch&iacute;nh s&aacute;ch v&agrave; điều khoản sử dụng dịch vụ</h2>
        <p>C&aacute;c Điều khoản dưới đ&acirc;y (&ldquo;Điều khoản&rdquo;) điều chỉnh việc người d&ugrave;ng, một
            c&aacute; nh&acirc;n hoặc tổ chức, truy cập hoặc sử dụng c&aacute;c ứng dụng, trang web, nội dung, sản phẩm
            v&agrave; dịch vụ (&ldquo;Dịch vụ&rdquo;) của <strong>C&Ocirc;NG TY TNHH VUONGSOFT</strong>, một c&ocirc;ng
            ty TNHH tư nh&acirc;n th&agrave;nh lập tại Việt Nam, được Sở Kế hoạch Đầu tư H&agrave; Nội cấp Giấy Chứng
            nhận đăng k&yacute; doanh nghiệp số 0109320368 ng&agrave;y 24 th&aacute;ng 08 năm 2020 (&ldquo;C&ocirc;ng
            ty&rdquo;).</p>
        <p><strong><em>VUI L&Ograve;NG ĐỌC KỸ C&Aacute;C ĐIỀU KHOẢN N&Agrave;Y TRƯỚC KHI TRUY CẬP HOẶC SỬ DỤNG DỊCH
            VỤ.</em></strong></p>
        <p><strong>Điều 1: </strong><strong>&nbsp;Nội dung dịch vụ được cung cấp th&ocirc;ng qua nền tảng kết
            nối:</strong></p>
        <p><strong>1.1 Nền tảng c&ocirc;ng nghệ:&nbsp;</strong></p>
        <p>C&ocirc;ng ty cung cấp một nền tảng c&ocirc;ng nghệ cho ph&eacute;p người d&ugrave;ng sử dụng c&aacute;c ứng
            dụng tr&ecirc;n di động của C&ocirc;ng ty hoặc c&aacute;c trang web được C&ocirc;ng ty cung cấp như một phần
            của Dịch vụ (gọi chung l&agrave; &ldquo;Ứng dụng&rdquo;) để kết nối kh&aacute;ch h&agrave;ng c&oacute; nhu
            cầu t&igrave;m việc (gọi tắt l&agrave; người nhận việc) với một kh&aacute;ch h&agrave;ng kh&aacute;c sẵn
            s&agrave;ng cung cấp c&ocirc;ng việc ph&aacute;t sinh ngắn hạn (sau đ&acirc;y gọi tắt l&agrave; &ldquo;người
            giao việc&rdquo;) . Người d&ugrave;ng c&ocirc;ng nhận rằng người d&ugrave;ng sử dụng Ứng dụng để t&igrave;m
            kiếm v&agrave; kết nối người giao việc với người nhận việc để thực hiện c&ocirc;ng việc từng theo từng giờ
            hoặc từng buổi. Với mỗi lần kết nối người giao việc c&oacute; quyền lựa chọn c&aacute;c người nhận việc
            kh&aacute;c nhau tại c&aacute;c thời điểm kh&aacute;c nhau t&ugrave;y theo nhu cầu v&agrave; quyết định
            ri&ecirc;ng để thực hiện dịch vụ t&igrave;m việc. Do đ&oacute;, việc cung cấp dịch vụ việc l&agrave;m
            ph&aacute;t sinh ngắn hạn kh&ocirc;ng phải l&agrave; việc l&agrave;m thường xuy&ecirc;n theo quy định.
            C&ocirc;ng ty l&agrave; một doanh nghiệp cung cấp dịch vụ c&ocirc;ng nghệ v&agrave; kh&ocirc;ng cung cấp
            dịch vụ việc l&agrave;m, kh&ocirc;ng c&oacute; chức năng của doanh nghiệp hoạt động dịch vụ việc
            l&agrave;m.</p>
        <p><strong>C&ocirc;ng việc:</strong></p>
        <ul>
            <li>Bao gồm tất cả c&aacute;c c&ocirc;ng việc ph&aacute;t sinh như: sửa xe, chuyển đồ, gi&uacute;p việc,
                phi&ecirc;n dịch,...c&aacute;c c&ocirc;ng việc ngắn hạn c&oacute; thể ho&agrave;n th&agrave;nh trong
                v&agrave;i giờ hoặc v&agrave;i ng&agrave;y.
            </li>
        </ul>
        <p><strong>1.2. Dịch vụ kh&aacute;c:&nbsp;</strong></p>
        <ul>
            <li>L&agrave; c&aacute;c dịch vụ được hiển thị tr&ecirc;n ứng dụng Bujo.vn hoặc được tư vấn, truyền
                th&ocirc;ng tới kh&aacute;ch h&agrave;ng bằng bất cứ c&ocirc;ng cụ truyền th&ocirc;ng n&agrave;o
                kh&aacute;c do C&ocirc;ng ty TNHH Vuongsoft thực hiện.
            </li>
        </ul>
        <p><strong>Điều 2: Gi&aacute; trị hợp đồng v&agrave; thanh to&aacute;n:</strong></p>
        <p><strong>2.1 Gi&aacute; trị hợp đồng</strong></p>
        <ol>
            <li>Gi&aacute; trị Hợp đồng sẽ căn cứ theo nhu cầu sử dụng thực tế của kh&aacute;ch h&agrave;ng (loại
                c&ocirc;ng việc, thời gian, địa điểm) Gi&aacute; trị hợp đồng ho&agrave;n to&agrave;n được hai b&ecirc;n
                kh&aacute;ch h&agrave;ng (người nhận việc, người giao việc) thảo luận v&agrave; đưa ra gi&aacute; trị
                ph&ugrave; hợp. C&ocirc;ng ty kh&ocirc;ng can thiệp v&agrave;o vấn đề n&agrave;y.
            </li>
            <li>Mức gi&aacute; trong Hợp đồng &aacute;p dụng cho tất cả c&aacute;c c&ocirc;ng việc thời vụ, ph&aacute;t
                sinh ngắn hạn như sửa xe, vận chuyển đồ,..
            </li>
            <li>Mức gi&aacute; được &aacute;p dụng v&agrave;o c&aacute;c ng&agrave;y lễ tết:</li>
        </ol>
        <ul>
            <li>Việc đưa ra c&aacute;c mức gi&aacute; phụ thuộc v&agrave;o người giao việc sau đ&oacute; người nhận việc
                đưa ra c&aacute;c mức gi&aacute; m&igrave;nh mong muốn. Mức gi&aacute; do 2 b&ecirc;n kh&aacute;ch
                h&agrave;ng thống nhất, quyết định với nhau. B&ecirc;n c&ocirc;ng ty kh&ocirc;ng chịu tr&aacute;ch nhiệm
                về quyết định n&agrave;y.
            </li>
        </ul>
        <p><strong>2.2. Thanh to&aacute;n:</strong></p>
        <ol>
            <li>Kh&aacute;ch h&agrave;ng thanh to&aacute;n gi&aacute; trị Hợp đồng trước hoặc sau khi ho&agrave;n
                th&agrave;nh c&ocirc;ng việc.
            </li>
        </ol>
        <p>- Trong trường hợp hủy hợp đồng hoặc đổi lịch l&agrave;m việc người thu&ecirc; v&agrave; người l&agrave;m
            li&ecirc;n hệ v&agrave; thảo luận ri&ecirc;ng với nhau.</p>
        <p>C&ocirc;ng ty kh&ocirc;ng đảm bảo 100% việc c&oacute; người l&agrave;m nhận việc trong khoảng thời gian
            n&agrave;y.&nbsp;</p>
        <p>- Cơ sở t&iacute;nh ph&iacute; dịch vụ: L&agrave; khoảng thời gian kh&aacute;ch h&agrave;ng đăng
            k&yacute; g&oacute;i trả ph&iacute; của dịch vụ, t&iacute;nh từ ng&agrave;y đăng k&yacute; đến khi hết số
            ng&agrave;y trong g&oacute;i dịch vụ m&agrave; kh&aacute;ch h&agrave;ng đăng k&iacute;, khoảng thời gian
            n&agrave;y được ghi nhận v&agrave; th&ocirc;ng b&aacute;o tr&ecirc;n ứng dụng Bujo.vn d&agrave;nh cho
            Kh&aacute;ch h&agrave;ng. Để theo d&otilde;i được th&ocirc;ng tin n&agrave;y Kh&aacute;ch h&agrave;ng cần
            c&agrave;i ứng dụng Bujo.vn (<strong>l</strong><strong>ink tải như sau: iOS: [...]
                Android:[...]</strong><strong>).</strong></p>
        <p>C&aacute;c g&oacute;i trả ph&iacute;:</p>
        <ul>
            <li>Gi&aacute;: 100.000 VNĐ/ 1 th&aacute;ng</li>
            <li>Gi&aacute;: 250.000 VNĐ/ 3 th&aacute;ng</li>
            <li>Gi&aacute;: 500.000 VNĐ/ 6 th&aacute;ng</li>
        </ul>
        <p>- Trong trường hợp giờ l&agrave;m việc trong ng&agrave;y ph&aacute;t sinh ngo&agrave;i khung giờ
            đ&atilde; thỏa thuận, 2 b&ecirc;n kh&aacute;ch h&agrave;ng tự thỏa thuận với nhau, c&ocirc;ng ty kh&ocirc;ng
            chịu tr&aacute;ch nhiệm về vấn đề đ&oacute;.</p>
        <ol>
            <li>H&igrave;nh thức thanh to&aacute;n:</li>
        </ol>
        <p>Thanh to&aacute;n qua VNPay, hoặc c&aacute;c v&iacute; điện tử do ứng dụng cung cấp.</p>
        <p>Trong v&ograve;ng 24h kể từ khi Qu&yacute; kh&aacute;ch thanh to&aacute;n, ch&uacute;ng t&ocirc;i sẽ gửi
            thư &ldquo;X&aacute;c nhận Thanh to&aacute;n Th&agrave;nh c&ocirc;ng&rdquo; tới địa chỉ email
            Qu&yacute; kh&aacute;ch đ&atilde; đăng k&yacute; hoặc Th&ocirc;ng b&aacute;o tr&ecirc;n Ứng dụng Bujo.vn,
            khi Qu&yacute; kh&aacute;ch cho ph&eacute;p gửi th&ocirc;ng b&aacute;o. Nếu Qu&yacute; kh&aacute;ch
            kh&ocirc;ng nhận được x&aacute;c nhận thanh to&aacute;n, vui l&ograve;ng phản hồi tới
            email<strong><em>support@vuongsoft.vn</em></strong>hoặc gọi hotline:<strong><em>0904 820 785</em></strong>để
            ch&uacute;ng t&ocirc;i kiểm tra v&agrave; xử l&yacute;.</p>
        <p>- Kh&aacute;ch h&agrave;ng đồng &yacute; trả c&aacute;c khoản chi ph&iacute; cho dịch vụ việc l&agrave;m
            (&ldquo;Ph&iacute;&rdquo;) c&oacute; được th&ocirc;ng qua kết nối. Sau khi Kh&aacute;ch h&agrave;ng nhận
            được dịch vụ việc l&agrave;m th&ocirc;ng qua việc sử dụng Dịch vụ, C&ocirc;ng ty kh&ocirc;ng chịu
            tr&aacute;ch nhiệm về những bất cứ vấn đề ph&aacute;t sinh n&agrave;o sau đ&oacute;.&nbsp;</p>
        <p><strong>Điều 3: Quyền v&agrave; nghĩa vụ của c&aacute;c B&ecirc;n:</strong></p>
        <p><strong>3.1. Quyền v&agrave; nghĩa vụ của Kh&aacute;ch h&agrave;ng:</strong></p>
        <p>3.1.1 Người d&ugrave;ng:</p>
        <p>(a) Tu&acirc;n thủ Thỏa thuận n&agrave;y (bao gồm tất cả c&aacute;c Ch&iacute;nh s&aacute;ch) v&agrave; tất
            cả c&aacute;c luật v&agrave; quy định hiện h&agrave;nh;</p>
        <p>(b) Chỉ đăng th&ocirc;ng tin ch&iacute;nh x&aacute;c tr&ecirc;n Nền tảng Bujo;</p>
        <p>(c) Đảm bảo rằng Bạn biết về bất kỳ luật n&agrave;o &aacute;p dụng cho bạn với tư c&aacute;ch l&agrave; Người
            nhận việc hoặc Người giao việc, hoặc li&ecirc;n quan đến việc sử dụng Nền tảng Bujo.</p>
        <p>3.1.2 Kh&ocirc;ng được sử dụng Nền tảng Bujo cho bất kỳ mục đ&iacute;ch bất hợp ph&aacute;p hoặc tr&aacute;i
            đạo đức n&agrave;o.</p>
        <p>3.1.3 Người d&ugrave;ng lu&ocirc;n phải duy tr&igrave; quyền kiểm so&aacute;t t&agrave;i khoản Bujo của
            m&igrave;nh. Điều n&agrave;y bao gồm việc kh&ocirc;ng cho ph&eacute;p người kh&aacute;c sử dụng t&agrave;i
            khoản của bạn hoặc bằng c&aacute;ch chuyển nhượng hoặc b&aacute;n t&agrave;i khoản của bạn hoặc bất kỳ nội
            dung n&agrave;o của t&agrave;i khoản đ&oacute; cho người kh&aacute;c.</p>
        <p>3.1.4 Bạn đồng &yacute; rằng bất kỳ th&ocirc;ng tin n&agrave;o được đăng tr&ecirc;n Nền tảng Bujo, dưới bất
            kỳ h&igrave;nh thức n&agrave;o, kh&ocirc;ng được c&oacute; khả năng hoặc thực sự g&acirc;y hại cho Bujo hoặc
            bất kỳ người n&agrave;o kh&aacute;c.&nbsp;</p>
        <p>3.1.5 Nếu Bạn l&agrave; Người giao việc, bạn c&oacute; quyền cung cấp Dịch vụ theo Hợp đồng c&ocirc;ng việc
            v&agrave; l&agrave;m việc trong phạm vi quyền hạn đ&oacute;. Bạn phải tu&acirc;n thủ c&aacute;c nghĩa vụ về
            thuế v&agrave; quy định li&ecirc;n quan đến bất kỳ khoản thanh to&aacute;n n&agrave;o nhận được theo Hợp
            đồng c&ocirc;ng việc.</p>
        <p>3.1.6 C&aacute;c b&ecirc;n trong Hợp đồng c&ocirc;ng việc c&oacute; thể đồng &yacute; sửa đổi Gi&aacute; Thỏa
            thuận th&ocirc;ng qua Nền tảng Bujo .</p>
        <p>3.1.7 Người đăng c&ocirc;ng việc thanh to&aacute;n b&ecirc;n ngo&agrave;i Nền tảng Bujo, thanh to&aacute;n
            trực tiếp cho người nhận việc, trước hoặc sau khi ho&agrave;n th&agrave;nh c&ocirc;ng việc. Hai b&ecirc;n tự
            thỏa thuận với nhau, c&ocirc;ng ty kh&ocirc;ng chịu tr&aacute;ch nhiệm khi xảy ra bất kỳ tranh chấp
            li&ecirc;n quan n&agrave;o.</p>
        <p><strong>3.2 Quyền v&agrave; nghĩa vụ của c&ocirc;ng ty.</strong></p>
        <p>3.2.1 C&ocirc;ng ty chỉ cung cấp Nền tảng Bujo, cho ph&eacute;p Người d&ugrave;ng đăng v&agrave; đưa ra mức
            trả gi&aacute; cho c&aacute;c c&ocirc;ng việc đ&atilde; đăng.</p>
        <p>&nbsp;3.2.2 Bujo chỉ cho ph&eacute;p c&aacute;c c&aacute; nh&acirc;n tr&ecirc;n 16 tuổi trở th&agrave;nh
            Người d&ugrave;ng.</p>
        <p>3.2.3 Người d&ugrave;ng phải l&agrave; c&aacute; nh&acirc;n, nhưng c&oacute; thể chỉ r&otilde; trong
            m&ocirc; tả t&agrave;i khoản của họ rằng họ đại diện cho một thực thể kinh doanh.</p>
        <p>3.2.4 Với quyền quyết định của m&igrave;nh, c&ocirc;ng ty c&oacute; thể từ chối cho ph&eacute;p bất kỳ người
            n&agrave;o đăng k&yacute;, tạo t&agrave;i khoản với Bujo hoặc hủy bỏ, tạm ngừng, sửa đổi bất kỳ t&agrave;i
            khoản hiện c&oacute; n&agrave;o, kể cả khi c&ocirc;ng ty c&oacute; quan điểm về h&agrave;nh vi hợp
            l&yacute; của Người d&ugrave;ng (bao gồm vi phạm Thỏa thuận n&agrave;y) l&agrave; g&acirc;y bất lợi cho hoạt
            động của Nền tảng Bujo.</p>
        <p>3.2.5 Đăng k&yacute; v&agrave; tạo t&agrave;i khoản với Bujo ho&agrave;n to&agrave;n miễn ph&iacute;. Người
            đăng b&agrave;i đăng c&aacute;c c&ocirc;ng việc hoặc Người d&ugrave;ng Bujo kh&aacute;c đ&aacute;nh
            gi&aacute; nội dung tr&ecirc;n Nền tảng Bujo, bao gồm cả c&ocirc;ng việc đ&atilde; đăng th&igrave; mới
            t&iacute;nh ph&iacute; theo c&aacute;c g&oacute;i người d&ugrave;ng trả ph&iacute; m&agrave; nền tảng cung
            cấp.</p>
        <p>3.2.6 Bujo kh&ocirc;ng chịu tr&aacute;ch nhiệm ph&aacute;p l&yacute; đối với bất kỳ kh&iacute;a cạnh
            n&agrave;o của tương t&aacute;c giữa Người đăng v&agrave; Người nhận việc, cũng như kh&ocirc;ng giới hạn ở
            việc m&ocirc; tả, thực hiện hoặc cung cấp Dịch vụ.</p>
        <p>3.2.7 Bujo kh&ocirc;ng chịu tr&aacute;ch nhiệm v&agrave; kh&ocirc;ng đảm bảo về t&iacute;nh trung thực hoặc
            t&iacute;nh ch&iacute;nh x&aacute;c của bất kỳ kh&iacute;a cạnh n&agrave;o của bất kỳ th&ocirc;ng tin
            n&agrave;o do Người đăng cung cấp, v&agrave; khả năng thực hiện c&ocirc;ng việc của người nhận việc hoặc
            cung cấp dụng cụ, hoặc t&iacute;nh trung thực hoặc ch&iacute;nh x&aacute;c của bất kỳ th&ocirc;ng tin được
            cung cấp bởi những người đăng c&ocirc;ng việc hoặc khả năng thanh to&aacute;n của người đăng cho c&aacute;c
            Dịch vụ được y&ecirc;u cầu.</p>
        <p>3.2.8 Bujo kh&ocirc;ng c&oacute; nghĩa vụ đối với bất kỳ Người d&ugrave;ng n&agrave;o để hỗ trợ hoặc
            li&ecirc;n quan đến bất kỳ tranh chấp n&agrave;o giữa Người d&ugrave;ng.</p>
        <p>3.2. 9 Người d&ugrave;ng hiểu v&agrave; đồng &yacute; rằng Bujo kh&ocirc;ng thực hiện bất kỳ h&agrave;nh động
            n&agrave;o li&ecirc;n quan đến bất kỳ người giao việc hoặc nh&agrave; cung cấp dịch vụ b&ecirc;n thứ ba
            n&agrave;o trước khi họ được chấp nhận v&agrave;o nền tảng, bao gồm kiểm tra danh t&iacute;nh, x&aacute;c
            minh bằng cấp hoặc giấy ph&eacute;p, hoặc c&aacute;c kiểm tra kh&aacute;c về t&iacute;nh ph&ugrave; hợp của
            người giao việc hoặc nh&agrave; cung cấp dịch vụ b&ecirc;n thứ ba để thực hiện bất kỳ hoạt động n&agrave;o
            m&agrave; họ tuy&ecirc;n bố c&oacute; thể cung cấp tr&ecirc;n nền tảng. Người d&ugrave;ng ho&agrave;n
            to&agrave;n chịu tr&aacute;ch nhiệm thực hiện bất kỳ kiểm tra l&yacute; lịch th&iacute;ch hợp n&agrave;o
            v&agrave; lấy c&aacute;c t&agrave;i liệu tham khảo, giấy ph&eacute;p, chứng nhận hoặc bằng chứng bảo hiểm
            trước khi giao nhiệm vụ thực hiện c&aacute;c dịch vụ. Người d&ugrave;ng ho&agrave;n to&agrave;n chịu
            tr&aacute;ch nhiệm về việc đưa ra c&aacute;c đ&aacute;nh gi&aacute;, quyết định v&agrave; đ&aacute;nh
            gi&aacute; của ri&ecirc;ng m&igrave;nh về việc lựa chọn người giao việc.</p>
        <p><strong>Điều 4: Cam kết thực hiện:</strong></p>
        <p>4.1. C&aacute;c B&ecirc;n cam kết thực hiện đ&uacute;ng v&agrave; đầy đủ c&aacute;c nghĩa vụ trong Hợp
            đồng.</p>
        <p>4.2. Trong qu&aacute; tr&igrave;nh thực hiện Hợp đồng, nếu c&oacute; ph&aacute;t sinh vấn đề ảnh hưởng đến
            quyền lợi của c&aacute;c B&ecirc;n, B&ecirc;n bị ảnh hưởng phải th&ocirc;ng b&aacute;o cho B&ecirc;n kia
            trong thời hạn 24 giờ kể từ l&uacute;c ph&aacute;t sinh vấn đề.</p>
        <p>4.3. C&aacute;c B&ecirc;n cam kết t&ocirc;n trọng quyền lợi ch&iacute;nh đ&aacute;ng của nhau, cam kết
            kh&ocirc;ng tiết lộ th&ocirc;ng tin Hợp đồng cho bất kỳ B&ecirc;n thứ 3 n&agrave;o kh&aacute;c.</p>
        <p>4.4. Trường hợp Kh&aacute;ch h&agrave;ng giao ch&igrave;a kh&oacute;a cho đối t&aacute;c đến địa điểm để thực
            hiện c&ocirc;ng việc, người giao việc cam kết h&agrave;nh vi n&agrave;y của Kh&aacute;ch h&agrave;ng
            kh&ocirc;ng tạo ra quan hệ gửi giữ t&agrave;i sản giữa người giao việc v&agrave; người nhận việc. C&ocirc;ng
            ty sẽ được miễn mọi tr&aacute;ch nhiệm ph&aacute;t sinh trong trường hợp n&agrave;y.-</p>
        <p><strong>Điều 5: Bất khả kh&aacute;ng:</strong></p>
        <p>C&aacute;c b&ecirc;n được miễn tr&aacute;ch nhiệm thực hiện nghĩa vụ của m&igrave;nh được quy định trong Hợp
            đồng trong c&aacute;c trường hợp sau:&nbsp;</p>
        <p>5.1. Trong c&aacute;c trường hợp Bất khả kh&aacute;ng (thi&ecirc;n tai, dịch họa, động đất, chiến
            tranh&hellip;) theo như quy định của ph&aacute;p luật Việt Nam, c&aacute;c B&ecirc;n sẽ được miễn
            tr&aacute;ch nhiệm thực hiện nghĩa vụ của m&igrave;nh được quy định trong Hợp đồng.</p>
        <p>5.2.Một trong hai b&ecirc;n bị ph&aacute; sản, tuy&ecirc;n bố ph&aacute; sản, mất khả năng thanh
            to&aacute;n.</p>
        <p><strong>Điều 6: Giải quyết tranh chấp v&agrave; khiếu nại:</strong></p>
        <p>6.1 Bujo khuyến kh&iacute;ch người d&ugrave;ng trực tiếp thử giải quyết c&aacute;c tranh chấp (bao gồm cả
            y&ecirc;u cầu ho&agrave;n tiền) với những Người d&ugrave;ng kh&aacute;c.&nbsp;</p>
        <p>6.2 Nếu tranh chấp ph&aacute;t sinh với Người d&ugrave;ng kh&aacute;c, Bạn phải hợp t&aacute;c với Người
            d&ugrave;ng kia v&agrave; cố gắng thực sự để giải quyết tranh chấp.</p>
        <p>6.3 Bujo c&oacute; thể hỗ trợ Người d&ugrave;ng giải quyết c&aacute;c tranh chấp. Bất kỳ Người d&ugrave;ng
            n&agrave;o cũng c&oacute; thể đưa tranh chấp đến Bujo. Bạn phải hợp t&aacute;c với bất kỳ cuộc điều tra
            n&agrave;o do Bujo thực hiện. Bujo c&oacute; quyền đưa ra quyết định cuối c&ugrave;ng (h&agrave;nh động hợp
            l&yacute;) dựa tr&ecirc;n th&ocirc;ng tin do Người d&ugrave;ng cung cấp.&nbsp;</p>
        <p>18.7 Nếu Bạn c&oacute; khiếu nại về Dịch vụ Bujo, vui l&ograve;ng li&ecirc;n hệ với ch&uacute;ng t&ocirc;i
            qua email<strong><em>support@vuongsoft.vn</em></strong>hoặc gọi hotline:<strong><em>0904 820
                785</em></strong></p>
        <p>18.8 Nếu Bujo cung cấp th&ocirc;ng tin về Người d&ugrave;ng kh&aacute;c cho bạn với mục đ&iacute;ch giải
            quyết tranh chấp theo điều khoản n&agrave;y, Bạn thừa nhận v&agrave; đồng &yacute; rằng th&ocirc;ng tin
            đ&oacute; sẽ chỉ được sử dụng cho mục đ&iacute;ch giải quyết tranh chấp (v&agrave; kh&ocirc;ng c&oacute; mục
            đ&iacute;ch n&agrave;o kh&aacute;c) v&agrave; bạn sẽ phải chịu tr&aacute;ch nhiệm v&agrave; chịu
            tr&aacute;ch nhiệm với c&ocirc;ng ty về bất kỳ chi ph&iacute;, tổn thất hoặc tr&aacute;ch nhiệm ph&aacute;p
            l&yacute; n&agrave;o m&agrave; Bujo phải chịu li&ecirc;n quan đến bất kỳ khiếu nại n&agrave;o li&ecirc;n
            quan đến bất kỳ việc sử dụng th&ocirc;ng tin n&agrave;o kh&aacute;c kh&ocirc;ng được Thỏa thuận n&agrave;y
            cho ph&eacute;p.</p>
        <p><strong>Điều 7: Ch&iacute;nh s&aacute;ch bảo mật th&ocirc;ng tin Kh&aacute;ch h&agrave;ng</strong></p>
        <p>Bảo mật dữ liệu l&agrave; vấn đề của sự tin tưởng. Bujo.vn nhận thức được rằng việc bảo mật th&ocirc;ng
            tin &amp; danh t&iacute;nh của qu&yacute; kh&aacute;ch đặc biệt quan trọng. V&igrave; vậy, ch&uacute;ng
            t&ocirc;i cam kết sử dụng những th&ocirc;ng tin m&agrave; qu&yacute; kh&aacute;ch cung cấp theo c&aacute;ch
            được đề ra trong Ch&iacute;nh s&aacute;ch Bảo mật n&agrave;y.</p>
        <p>Ch&uacute;ng t&ocirc;i sẽ thu thập những th&ocirc;ng tin cần thiết v&agrave; c&oacute; li&ecirc;n quan đến
            giao dịch giữa ch&uacute;ng t&ocirc;i v&agrave; người d&ugrave;ng.</p>
        <p>Ch&uacute;ng t&ocirc;i sử dụng c&ocirc;ng nghệ theo dấu kh&aacute;c để thu thập một số th&ocirc;ng tin khi
            người d&ugrave;ng tương t&aacute;c tr&ecirc;n website &amp; ứng dụng <em>Bujo.vn</em> cho mục đ&iacute;ch
            quảng c&aacute;o &amp; ch&iacute;nh sửa sản phẩm để n&acirc;ng cao trải nghiệm.</p>
        <p>Ch&uacute;ng t&ocirc;i cam kết giữ th&ocirc;ng tin của qu&yacute; kh&aacute;ch trong thời gian luật
            ph&aacute;p y&ecirc;u cầu hoặc cho mục đ&iacute;ch m&agrave; th&ocirc;ng tin đ&oacute; được thu thập.</p>
        <p>Qu&yacute; kh&aacute;ch c&oacute; thể gh&eacute; thăm website m&agrave; kh&ocirc;ng cần phải cung cấp bất kỳ
            th&ocirc;ng tin c&aacute; nh&acirc;n n&agrave;o trừ khi qu&yacute; kh&aacute;ch c&oacute; t&agrave;i khoản
            v&agrave; đăng nhập v&agrave;o bằng t&ecirc;n v&agrave; mật khẩu của m&igrave;nh.</p>
        <p><strong>7.1. Mục đ&iacute;ch &amp; phạm vi thu thập th&ocirc;ng tin Kh&aacute;ch h&agrave;ng</strong></p>
        <p>7.1.1. Khi qu&yacute; kh&aacute;ch đăng k&yacute; t&agrave;i khoản tại ứng dụng Bujo, th&ocirc;ng tin
            c&aacute; nh&acirc;n m&agrave; ch&uacute;ng t&ocirc;i thu thập bao gồm:</p>
        <ul>
            <li>Họ &amp; T&ecirc;n Kh&aacute;ch h&agrave;ng</li>
            <li>Giới t&iacute;nh</li>
            <li>Ng&agrave;y sinh</li>
            <li>Địa chỉ sử dụng dịch vụ</li>
            <li>Địa chỉ Email</li>
            <li>Số điện thoại li&ecirc;n hệ&nbsp;</li>
        </ul>
        <p><strong>7.1.2. Những th&ocirc;ng tin tr&ecirc;n sẽ được sử dụng cho một hoặc tất cả c&aacute;c mục
            đ&iacute;ch sau đ&acirc;y:</strong></p>
        <p>.Cung cấp th&ocirc;ng tin li&ecirc;n quan đến c&aacute;c dịch vụ do Bujo.vn cung cấp &amp; c&aacute;c
            ch&iacute;nh s&aacute;ch hỗ trợ của Bujo.vn. Xử l&yacute; c&aacute;c y&ecirc;u cầu cung cấp dịch vụ
            v&agrave; th&ocirc;ng tin qua ứng dụng &amp; website của ch&uacute;ng t&ocirc;i theo y&ecirc;u cầu của
            qu&yacute; kh&aacute;ch. Ngo&agrave;i ra, ch&uacute;ng t&ocirc;i sẽ sử dụng th&ocirc;ng tin người
            d&ugrave;ng cung cấp để hỗ trợ quản l&yacute; t&agrave;i khoản người d&ugrave;ng kh&aacute;c; x&aacute;c
            nhận v&agrave; thực hiện c&aacute;c giao dịch t&agrave;i ch&iacute;nh li&ecirc;n quan đến c&aacute;c khoản
            thanh to&aacute;n trực tuyến của qu&yacute; kh&aacute;ch qua ứng dụng; kiểm tra dữ liệu tải từ website của
            ch&uacute;ng t&ocirc;i; cải thiện giao diện hoặc nội dung của c&aacute;c trang mục tr&ecirc;n ứng
            dụng &amp; website để Kh&aacute;ch h&agrave;ng dễ d&agrave;ng hơn khi sử dụng; nhận diện kh&aacute;ch đến
            thăm website &amp; ứng dụng; nghi&ecirc;n cứu về nh&acirc;n khẩu học của người d&ugrave;ng; gửi đến
            qu&yacute; kh&aacute;ch th&ocirc;ng tin m&agrave; ch&uacute;ng t&ocirc;i nghĩ sẽ c&oacute; &iacute;ch hoặc
            do qu&yacute; kh&aacute;ch y&ecirc;u cầu, bao gồm th&ocirc;ng tin về sản phẩm v&agrave; dịch vụ, với điều
            kiện qu&yacute; kh&aacute;ch đồng &yacute; kh&ocirc;ng phản đối việc được li&ecirc;n lạc cho c&aacute;c mục
            đ&iacute;ch tr&ecirc;n.</p>
        <p><strong>7.1.3. Phạm vi sử dụng th&ocirc;ng tin Kh&aacute;ch h&agrave;ng:</strong></p>
        <p>Chia sẻ th&ocirc;ng tin của người d&ugrave;ng cho c&aacute;c đối t&aacute;c thanh to&aacute;n g&oacute;i trả
            ph&iacute; của ch&uacute;ng t&ocirc;i để c&oacute; thể thực hiện dịch vụ cho người d&ugrave;ng. Danh
            s&aacute;ch c&aacute;c đơn vị gồm: VNPay, V&iacute; Điện Tử MoMo, Visa, Card nội địa, Zalopay,....</p>
        <p>Gửi c&aacute;c th&ocirc;ng tin khuyến m&atilde;i/quảng c&aacute;o, cung cấp c&aacute;c dịch vụ
            tiện &iacute;ch đến qu&yacute; kh&aacute;ch. Qu&yacute; kh&aacute;ch c&oacute; thể hủy nhận c&aacute;c
            th&ocirc;ng tin đ&oacute; bất kỳ l&uacute;c n&agrave;o bằng c&aacute;ch sử dụng chức năng hủy đăng
            k&yacute; trong c&aacute;c th&ocirc;ng b&aacute;o quảng c&aacute;o.</p>
        <p>Li&ecirc;n lạc với qu&yacute; kh&aacute;ch v&agrave; giải quyết trong c&aacute;c trường hợp đặc biệt. Người
            d&ugrave;ng c&oacute; thể theo d&otilde;i chi tiết những đơn đ&atilde; ho&agrave;n tất, v&agrave; đơn sắp
            được thực hiện; đồng thời quản l&yacute; &amp; thay đổi th&ocirc;ng tin c&aacute; nh&acirc;n...</p>
        <p><strong>7.2. Cập nhật thay đổi th&ocirc;ng tin c&aacute; nh&acirc;n</strong></p>
        <p>Qu&yacute; kh&aacute;ch c&oacute; thể cập nhật th&ocirc;ng tin c&aacute; nh&acirc;n của m&igrave;nh bất kỳ
            l&uacute;c n&agrave;o bằng c&aacute;ch đăng nhập v&agrave;o ứng dụng Bujo.vn.</p>
        <p>Qu&yacute; kh&aacute;ch cần bảo đảm l&agrave; th&ocirc;ng tin được truy cập một c&aacute;ch b&iacute; mật
            v&agrave; kh&ocirc;ng l&agrave;m lộ cho một b&ecirc;n thứ ba kh&aacute;c kh&ocirc;ng c&oacute; quyền.
            C&ocirc;ng ty sẽ kh&ocirc;ng chịu tr&aacute;ch nhiệm đối với việc sử dụng sai mật khẩu trừ khi
            đ&oacute; l&agrave; lỗi của ch&uacute;ng t&ocirc;i.</p>
        <p><strong>7.3. Bảo vệ th&ocirc;ng tin Kh&aacute;ch h&agrave;ng</strong></p>
        <p>Bujo.vn đảm bảo rằng mọi th&ocirc;ng tin thu thập được sẽ được lưu giữ an to&agrave;n. Ch&uacute;ng t&ocirc;i
            bảo vệ th&ocirc;ng tin c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch bằng c&aacute;ch:&nbsp;</p>
        <ul>
            <li>Giới hạn truy cập th&ocirc;ng tin c&aacute; nh&acirc;n.</li>
            <li>Sử dụng sản phẩm c&ocirc;ng nghệ bảo mật cao nhất để ngăn chặn truy cập hệ thống quản l&yacute; dữ liệu
                nội bộ tr&aacute;i ph&eacute;p.
            </li>
            <li>X&oacute;a mọi th&ocirc;ng tin c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch khi những th&ocirc;ng
                tin đ&oacute; kh&ocirc;ng c&ograve;n cần thiết cho mục đ&iacute;ch lưu trữ dữ liệu của ch&uacute;ng
                t&ocirc;i.
            </li>
        </ul>
        <p><strong>7.4. Ti&ecirc;u chuẩn bảo mật với giao dịch thanh to&aacute;n điện tử</strong></p>
        <p>7.4.1. C&aacute;c giao dịch thanh to&aacute;n bằng thẻ quốc tế v&agrave; thẻ nội địa (internet banking) đảm
            bảo tu&acirc;n thủ c&aacute;c ti&ecirc;u chuẩn bảo mật của c&aacute;c Đối T&aacute;c Cổng Thanh To&aacute;n,
            gồm:</p>
        <p>Th&ocirc;ng tin t&agrave;i ch&iacute;nh của Kh&aacute;ch h&agrave;ng sẽ được bảo vệ trong suốt
            qu&aacute; tr&igrave;nh giao dịch bằng giao thức SSL (Secure Sockets Layer).Chứng nhận ti&ecirc;u chuẩn bảo
            mật dữ liệu th&ocirc;ng tin thanh to&aacute;n (PCI DSS) do Trustwave cung cấp. Mật khẩu sử dụng một lần
            (OTP) được gửi qua SMS để đảm bảo việc truy cập t&agrave;i khoản được x&aacute;c thực.Ti&ecirc;u chuẩn
            m&atilde; h&oacute;a SHA 512 bit. C&aacute;c nguy&ecirc;n tắc v&agrave; quy định bảo mật th&ocirc;ng tin
            trong ng&agrave;nh t&agrave;i ch&iacute;nh ng&acirc;n h&agrave;ng theo quy định của Ng&acirc;n h&agrave;ng
            nh&agrave; nước Việt Nam.&nbsp;</p>
        <p>7.4.2. Ch&iacute;nh s&aacute;ch bảo mật giao dịch trong thanh to&aacute;n của Bujo.vn &aacute;p dụng với
            Kh&aacute;ch h&agrave;ng:</p>
        <p>Bujo.vn cam kết đảm bảo thực hiện nghi&ecirc;m t&uacute;c c&aacute;c biện ph&aacute;p bảo mật cần thiết cho
            mọi hoạt động thanh to&aacute;n thực hiện tại ứng dụng Bujo.vn</p>
        <p><strong>7.5. Cam kết của Bujo.vn</strong></p>
        <p>Ch&uacute;ng t&ocirc;i sẽ kh&ocirc;ng chia sẻ hoặc tiết lộ th&ocirc;ng tin của qu&yacute; kh&aacute;ch cho
            bất kỳ một tổ chức/c&aacute; nh&acirc;n n&agrave;o kh&aacute;c ngoại trừ những đối t&aacute;c
            v&agrave; c&aacute;c b&ecirc;n thứ ba c&oacute; li&ecirc;n quan trực tiếp đến việc giao dịch trực tuyến hoặc
            thực hiện dịch vụ m&agrave; qu&yacute; kh&aacute;ch đ&atilde; đặt tại ứng dụng Bujo.</p>
        <p>Trong một v&agrave;i trường hợp đặc biệt, Bujo.vn c&oacute; thể bị y&ecirc;u cầu phải tiết lộ th&ocirc;ng tin
            c&aacute; nh&acirc;n, v&iacute; dụ khi c&oacute; căn cứ cho việc tiết lộ th&ocirc;ng tin l&agrave; cần thiết
            để ngăn chặn c&aacute;c mối đe dọa về t&iacute;nh mạng v&agrave; sức khỏe, hay cho mục đ&iacute;ch thực thi
            ph&aacute;p luật. Bujo.vn cam kết tu&acirc;n thủ Điều Luật Bảo Mật v&agrave; c&aacute;c Nguy&ecirc;n tắc Bảo
            mật Quốc gia.</p>
        <p>Nếu qu&yacute; kh&aacute;ch tin rằng th&ocirc;ng tin của qu&yacute; kh&aacute;ch bị Bujo.vn x&acirc;m phạm,
            xin vui l&ograve;ng li&ecirc;n hệ với ch&uacute;ng t&ocirc;i theo số hotline: <strong><em>0904 820 785</em></strong> để
            được giải quyết.</p>
        <p><em>7.6. Thay đổi của Ch&iacute;nh s&aacute;ch Bảo mật</em></p>
        <p>Bujo.vn c&oacute; quyền thay đổi v&agrave; chỉnh sửa Quy định Bảo mật n&agrave;y v&agrave;o bất kỳ l&uacute;c
            n&agrave;o. Bất cứ sự thay đổi n&agrave;o về ch&iacute;nh s&aacute;ch n&agrave;y đều được đăng tr&ecirc;n
            trang website &amp; ứng dụng <strong><em>Bujo.vn</em></strong> của ch&uacute;ng t&ocirc;i.</p>
    </div>
</div>
export default Terms;