import {Component, useState} from "react";
import MenuIcon from "./NavBar/MenuIcon";
import {HelpItem, QuestionItem} from "./NavBar/MenuItems";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
class Help extends Component {
    render() {
        return (
            <div>
                <ScrollToTopOnMount />
                <div className=" menu-position">
                    <MenuIcon/>
                </div>
                <div className="help-bg"></div>
                <div className="container m-top">
                    <h2>Hướng dẫn </h2>
                    <div className="row mt-5">
                        <div className="col-3 time-line">
                            <h3>Mục lục</h3>
                            <div className="content-line">
                                {
                                    HelpItem.map((item, index) => {
                                        return <div className="block-content mt-3" key={index}>
                                            {item.title}
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-md-8 col-12 px-5 content-details">
                            {
                                HelpItem.map((item, index) => {
                                    return <div key={index} id={"Table-content-" + index}>
                                        <h4>{item.title}</h4>
                                        {item.img ?  <img src={require("../img/gallery/" + item.img + ".png").default} className="py-5" alt=""/> : <div></div> }
                                        <h5>{item.content}</h5>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="container m-top px-5">
                    <h2>Các câu hỏi thường gặp</h2>
                    <div className="question m-top px-5">
                        {
                            QuestionItem.map((item,index) => {
                                return <div>
                                    <h4>
                                        {item.title}
                                    </h4>
                                    <h5>
                                        {item.content}
                                    </h5>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Help