import {NewsItem} from "./NewsItem";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import {Link, useParams} from "react-router-dom";
import {Component} from "react";
import MenuIcon from "../NavBar/MenuIcon";

export function NewsArea() {
    let param = useParams();
    const res = NewsItem.find(item => item.id === param.id)
    console.log(param.id)
    return <div>
        {
            <div>
                <ScrollToTopOnMount/>
                <div className=" menu-position">
                    <MenuIcon/>
                </div>
                <div className="container m-top">
                    <div>
                        <h3 className="mb-5">Tin mới cập nhật</h3>
                        <nav aria-label="breadcrumb ">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/#">
                                    Home
                                </Link></li>
                                <li className="breadcrumb-item"><Link to="/News">
                                    News
                                </Link>
                                </li>
                                <li className="breadcrumb-item">{res.title}</li>
                            </ol>
                        </nav>
                        <h2 className="mt-5">
                            {res.title}
                        </h2>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-3 col-12 timeline">
                            <h3>Mục lục</h3>
                            <div className="content-line">{
                                res.details.map((item, index) => {
                                    return <div className="block-content mt-3" key={index}>
                                        {item.head}
                                    </div>
                                })
                            }
                            </div>
                        </div>
                        <div className="col-md-8 col-12 px-5 content-details">
                            {
                                res.details.map((item, index) => {
                                        return <div key={index} id={"Table-content-" + index}>
                                            <h4>{item.head}</h4>
                                            {item.img ? <img src={item.img}
                                                             className="py-5" style={{width: '100%'}} alt=""/> :
                                                <div></div>}
                                            <h5>{item.content}</h5>
                                        </div>
                                    }
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
}

class News extends Component {
    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <div className="m-top bg-light p-5">
                    <h2>Tin tức</h2>
                    <div className="container mb-5 mt-5 news ">
                        <div className="row">
                            {
                                NewsItem.map((item, index) => {
                                    return <div className="col-md-4">
                                        <div className="col max-mb-30 aos-init aos-animate" data-aos="fade-up">
                                            <Link to={"/News/" + item.id}>
                                                <a href="#" className="icon-box text-center"
                                                   data-bg-color="#fff"
                                                   style={{backgroundColor: 'rgb(255, 255, 255)'}}
                                                   data-abc="true">
                                                    <div className="icon"><img src={item.img}
                                                                               width={240}/></div>
                                                    <div className="content">
                                                        <h3 className="title fz-20">{item.title}</h3>
                                                        <div className="desc">
                                                            <p>{item.content}</p>
                                                        </div>
                                                        <span className="link">Đọc thêm <i
                                                            className="fa fa-arrow-right"/></span>
                                                    </div>
                                                </a>
                                            </Link>
                                        </div>
                                    </div>
                                })
                            }


                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default News