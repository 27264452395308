import {Component} from "react";
import './AdsComp.css'
import btnPlay from '../../img/hero/play.png';
import btnApp from '../../img/hero/app.png';

class AdsComp extends Component {
    render() {
        return (
            <div className="mt-5">
                <div className="container">
                    <div className="px-5">
                        <h2>Giải pháp công nghệ cho các công việc ngắn hạn
                        </h2>
                    </div>
                    <div className="row mt-4 d-flex justify-content-center">
                        <div className="card reason-card mx-3">
                            <h5><strong> Tại sao cần Người nhận việc ?
                            </strong></h5>
                            <h5>
                                Tìm kiếm các công việc ngắn hạn nhằm mục đích kiếm thêm thu nhập,
                            </h5>
                        </div>
                        <div className="card reason-card mx-3">
                            <h5><strong> Tại sao cần Người giao việc ?
                            </strong></h5>
                            <h5>
                                Tìm người làm các công việc phát sinh như sửa nhà, dọn vườn, vận chuyển,.. nhanh chóng
                                và tiết kiệm thời gian.
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="container-fluid m-top">
                    <div className="container mt-5 window-card">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-5 col-12">
                                <div className="win-img"></div>
                            </div>
                            <div className="col-md-7 col-12 p-5">
                                <h3>Trải nghiệm 1 tháng sử dụng miễn phí</h3>
                                <h5 className="mb-4">
                                    Tải và trải nghiệm 1 tháng sử dụng tính năng trải phí với những tính không giới
                                    hạn, tích lũy điểm thưởng và nhiều hơn nữa...
                                </h5>
                                <div className="justify-content-center">
                                    <div className="download-button mr-2">
                                        <a href="https://apps.apple.com/us/app/bujo/id1577352348">
                                            <img src={btnApp} alt=""/>
                                        </a>
                                    </div>
                                    <div className="download-button">
                                        <a href="https://play.google.com/store/apps/details?id=vn.vuongsoft.bujo">
                                            <img src={btnPlay} alt=""/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

export default AdsComp