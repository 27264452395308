import {Component} from "react";
import {Link} from "react-router-dom";
class DonateComp extends Component{
    render() {
        return(
            <div>
                <div className="container-fluid p-5 m-top super-center ">
                    <h2>Tài trợ</h2>
                    <h2> Trở thành nhà tài trợ của Bujo</h2>
                    <Link to="/submit">
                        <div className="btn">Tìm hiểu </div>
                    </Link>
                </div>
            </div>
        )
    }
}
export default DonateComp