export const NewsItem = [
    {
        id: "00011",
        img: "https://cdnimg.vietnamplus.vn/t1200/Uploaded/ngtmbh/2021_07_23/ttxvn_lao_dong_tu_do.jpg",
        title: "Việc làm trong mùa Covid",
        content: " Việc làm được xem là vấn đề vô cùng nan giản trong thời kỳ dịch bệnh. Phải nói rằng dịch bệnh đã để lại cho đất nước ta rất nhiều thiệt hại về con người và kinh tế. Hàng loạt các hoạt động như du lịch, ăn uống, xem phim,.. đều bị hạn chế.",
        details: [
            {
                img: "https://cdnimg.vietnamplus.vn/t1200/Uploaded/ngtmbh/2021_07_23/ttxvn_lao_dong_tu_do.jpg",
                head: "Trong tình hình dịch bệnh",
                content: "Việc làm được xem là vấn đề vô cùng nan giản trong thời kỳ dịch bệnh. Phải nói rằng dịch bệnh đã để lại cho đất nước ta rất nhiều thiệt hại về con người và kinh tế. Hàng loạt các hoạt động như du lịch, ăn uống, xem phim,.. đều bị hạn chế. Ở một số tỉnh thành là trung tâm của dịch bệnh, hầu như các hoạt động về kinh tế đều bị tê liệt. Điều này dẫn đến sức ép kinh tế vô cùng lớn đến các doanh nghiệp, chủ cửa hàng,... và dẫn đến nguy cơ thất nghiệp trong thời gian dài của người lao động.\n" +
                    "Vì vậy, sau khi dịch bệnh kết thúc, nhu cầu về tìm kiếm việc làm kiếm thêm thu nhập sau những ngày nghỉ là vô cùng lớn, đặc biệt là ở những người chưa có công việc ổn định như sinh viên hay lao động tự do. Có thể nói rằng việc tạo ra một kênh tìm việc chính thống là cần thiết, và hiệu quả không chỉ giải quyết được vấn đề việc làm và thu nhập sau dịch bệnh mà còn giúp tăng sự tin tưởng giữa những mối hợp tác ngắn hạn giữa người có công việc phát sinh và người thực hiện công việc đó. Điều này cũng một lần nữa giải quyết được những vấn đề tồn đọng trong lĩnh vực công việc phát sinh. Người có việc sẽ tìm được người phù hợp, đăng tin dễ dàng, tiết kiệm chi phí, tìm được người phù hợp. Người tìm việc cũng sẽ có nhiều sự lựa chọn hơn cho công việc của mình!"
            },
            {
                img: "https://biu.edu.vn/wp-content/uploads/2021/05/cach-day-hoc-online-914x600-1.jpg",
                head: "Sự bắt buộc phải thích nghi với dịch bệnh",
                content: "Việc làm được xem là vấn đề vô cùng nan giản trong thời kỳ dịch bệnh. Phải nói rằng dịch bệnh đã để lại cho đất nước ta rất nhiều thiệt hại về con người và kinh tế. Hàng loạt các hoạt động như du lịch, ăn uống, xem phim,.. đều bị hạn chế. Ở một số tỉnh thành là trung tâm của dịch bệnh, hầu như các hoạt động về kinh tế đều bị tê liệt. Điều này dẫn đến sức ép kinh tế vô cùng lớn đến các doanh nghiệp, chủ cửa hàng,... và dẫn đến nguy cơ thất nghiệp trong thời gian dài của người lao động.\n" +
                    "Vì vậy, sau khi dịch bệnh kết thúc, nhu cầu về tìm kiếm việc làm kiếm thêm thu nhập sau những ngày nghỉ là vô cùng lớn, đặc biệt là ở những người chưa có công việc ổn định như sinh viên hay lao động tự do. Có thể nói rằng việc tạo ra một kênh tìm việc chính thống là cần thiết, và hiệu quả không chỉ giải quyết được vấn đề việc làm và thu nhập sau dịch bệnh mà còn giúp tăng sự tin tưởng giữa những mối hợp tác ngắn hạn giữa người có công việc phát sinh và người thực hiện công việc đó. Điều này cũng một lần nữa giải quyết được những vấn đề tồn đọng trong lĩnh vực công việc phát sinh. Người có việc sẽ tìm được người phù hợp, đăng tin dễ dàng, tiết kiệm chi phí, tìm được người phù hợp. Người tìm việc cũng sẽ có nhiều sự lựa chọn hơn cho công việc của mình!"
            }
        ]
    },
    {
        id: "00012",
        img :"https://media.vneconomy.vn/w800/images/upload/2021/04/20/lao-dong-tre-157069408750919415276-crop-15706940912661242273548.jpg",
        title: "Nhu cầu việc làm phát sinh hiện nay",
        content: "Việt Nam là một đất nước với mật độ dân số cao, kéo theo đó là nhu cầu việc làm rất lớn. Vì vậy, tìm kiếm những người thực sự có chuyên môn, tay nghề để làm một công việc phát sinh trong khoảng thời gian ngắn lại càng không phải là điều dễ dàng.",
            details: [
            {
                head: "Hiện trạng",
                content: "Việt Nam là một đất nước với mật độ dân số khá cao, kéo theo đó là nhu cầu việc làm vô cùng lớn. Tuy nhiên trình độ của người lao động Việt Nam còn thấp. Vì vậy, tìm kiếm những người thực sự có chuyên môn, tay nghề để làm một công việc phát sinh trong khoảng thời gian ngắn lại càng không phải là điều dễ dàng."
            },
            {
                img :"https://media.vneconomy.vn/w800/images/upload/2021/04/20/lao-dong-tre-157069408750919415276-crop-15706940912661242273548.jpg",
                head: "Lao động tự do ở Việt Nam rất bị động",
                content: "Bên cạnh những điều đã nói ở trên, công việc phát sinh không mang tính ổn định, chỉ được xem là công việc kiếm thêm thu nhập vào thời gian rảnh. Chính vì thế mà đối tượng chủ yếu tham gia tìm kiếm, thực hiện các công việc phát sinh đa phần là sinh viên và lao động tự do.\n" +
                    "Trước tình hình dịch bệnh như hiện nay, đa phần lao động đẫ trở về quê để tránh dịch, việc tìm kiếm người làm lại càng trở nên khó khăn hơn bao giờ hết. Các kênh đăng việc, tìm kiếm người làm việc ở Việt Nam cũng chưa thực sự phát triển. Đa phần mọi người khi có công việc thường đăng đàn trên các group tìm việc trên Facebook. Dẫn đến thông tin thiếu tin cậy, không thu hút được nhiều người làm việc uy tín, đôi khi phải chọn những người làm không thực sự phù hợp với mức giá cao so với giá của thị trường việc làm lúc bây giờ. Chính vì thế mà nhu cầu về một kênh chính thống dành cho việc kết nối những người có công việc phát sinh và những người có khả năng hoàn thành công việc, đang tìm kiếm thêm thu nhập là vô cùng cần thiết."
            },
            {
                head: "Giải pháp",
                content: "Bujo"
            }
        ]
    },
    {
        id: "00013",
        img :"https://media.vneconomy.vn/w800/images/upload/2021/04/20/lao-dong-tre-157069408750919415276-crop-15706940912661242273548.jpg",
        title: "Nhu cầu việc làm phát sinh hiện nay",
        content: "Việt Nam là một đất nước với mật độ dân số cao, kéo theo đó là nhu cầu việc làm rất lớn. Vì vậy, tìm kiếm những người thực sự có chuyên môn, tay nghề để làm một công việc phát sinh trong khoảng thời gian ngắn lại càng không phải là điều dễ dàng.",
        details: [
            {
                head: "Hiện trạng",
                content: "Việt Nam là một đất nước với mật độ dân số khá cao, kéo theo đó là nhu cầu việc làm vô cùng lớn. Tuy nhiên trình độ của người lao động Việt Nam còn thấp. Vì vậy, tìm kiếm những người thực sự có chuyên môn, tay nghề để làm một công việc phát sinh trong khoảng thời gian ngắn lại càng không phải là điều dễ dàng."
            },
            {
                img :"https://media.vneconomy.vn/w800/images/upload/2021/04/20/lao-dong-tre-157069408750919415276-crop-15706940912661242273548.jpg",
                head: "Lao động tự do ở Việt Nam rất bị động",
                content: "Bên cạnh những điều đã nói ở trên, công việc phát sinh không mang tính ổn định, chỉ được xem là công việc kiếm thêm thu nhập vào thời gian rảnh. Chính vì thế mà đối tượng chủ yếu tham gia tìm kiếm, thực hiện các công việc phát sinh đa phần là sinh viên và lao động tự do.\n" +
                    "Trước tình hình dịch bệnh như hiện nay, đa phần lao động đẫ trở về quê để tránh dịch, việc tìm kiếm người làm lại càng trở nên khó khăn hơn bao giờ hết. Các kênh đăng việc, tìm kiếm người làm việc ở Việt Nam cũng chưa thực sự phát triển. Đa phần mọi người khi có công việc thường đăng đàn trên các group tìm việc trên Facebook. Dẫn đến thông tin thiếu tin cậy, không thu hút được nhiều người làm việc uy tín, đôi khi phải chọn những người làm không thực sự phù hợp với mức giá cao so với giá của thị trường việc làm lúc bây giờ. Chính vì thế mà nhu cầu về một kênh chính thống dành cho việc kết nối những người có công việc phát sinh và những người có khả năng hoàn thành công việc, đang tìm kiếm thêm thu nhập là vô cùng cần thiết."
            },
            {
                head: "Giải pháp",
                content: "Bujo"
            }
        ]
    },
]