import {Link} from "react-router-dom";

const  Thank = () =>(
    <div className=" h-50 super-center m-top p-5 ">
        <h1>Cám ơn!</h1>
        <div className="">
            <Link to="/" >
                <button className="btn">
                    Trở về
                </button>
            </Link>
        </div>
        <div className="line-wrap"></div>

        <div className="mt-2">
            <h5>
                Kiểm tra email của bạn,nếu không tìm thấy, kiểm tra mục <span>spam</span>
            </h5>
            <h5>
                nếu gặp vấn đề, <Link to="/submit">nhấp vào đây</Link>
            </h5>
        </div>

    </div>
)
export default Thank
