import {Component} from "react";
import "./VideoComp.css";
import video2Line from '../../img/gallery/hi1.mp4'
class VideoComp extends Component {
    render() {
        return (
            <div className="m-top">
                <div id="home-top-video">
                    <video autoPlay loop muted width="100%">
                        <source src={video2Line} type="video/mp4"/>
                    </video>
                    <div id="home-text">
                        <h3>Trở thành Tasker ngay hôm nay</h3>
                    </div>
                </div>

            </div>
        )
    }
}

export default VideoComp;