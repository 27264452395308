import {ContactItem} from "../NavBar/MenuItems";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
const Submit = () => <div>
    <ScrollToTopOnMount />
    <div className="one-height super-center submit-bg">
        <div className="container  csdk-pd">
            <div className="row bg-light p-5">
                <div className="col-md-6 col-12">
                    <h3>
                        Bạn cần hỗ trợ ?
                    </h3>
                    <h4>
                        Gửi cho chúng tôi vấn đề của bạn
                    </h4>
                    <h5><span>Hướng dẫn</span></h5>
                    <h5>Hãy mô tả chi tiết vấn đề cùa bạn cùng với thông tin, chúng tôi sẽ trả lời sớm nhất có thể</h5>
                    {
                        ContactItem.map((item,index) => {
                            return <h5 key={index}>
                                {item.cIcon}<a href={item.linkTo}> <span>{item.title}</span></a>
                            </h5>
                        })
                    }
                </div>
                <div className="col-md-6 col-12">
                    <form action="https://formsubmit.co/support@bujo.vn" method="POST">
                        <label htmlFor="fname">Họ tên</label>
                        <input type="text" placeholder="Nguyen Van A" name="name" required/>
                        <input type="hidden" name="_captcha" value="false"/>
                        <label htmlFor="fname">Email</label>
                        <input type="email" placeholder="examp@mail.com" name="email" required />
                        <label htmlFor="fname">Tin nhắn</label>
                        <textarea className="mess-form" type="text"/>
                        <input type="hidden" name="_next" value="https://bujo.vn/#/thank"/>
                        <button className="btn" type="submit">Gửi
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

export default Submit;