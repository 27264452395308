import {Component} from "react";
import './BgSlide.css';
import btnPlay from '../../img/hero/play.png';
import btnApp from '../../img/hero/app.png';
import NumberCounter from "number-counter";

class SlideComp extends Component {
    render() {
        return (
            <div>
                <div className=" align-items-center super-center">
                    <div className="bg-slide"></div>
                    <div className="container px-5 hero-text">
                        <div className="row ">
                            <div className="col-lg-6 col-md-12 d-flex flex-column">
                                <div className="hero-title">
                                    {/*<h5>Giải pháp công nghệ cho công việc ngắn hạn</h5>*/}
                                    <h1>Việc trong ngày, tiền trao liền tay!</h1>
                                    <div className="mb-2">
                                        <span>Nhập email để nhận được những thông tin mới nhất từ bujo.</span>
                                    </div>
                                    <div className="thangchamay">
                                        <form className="form" action="https://formsubmit.co/support@bujo.vn" method="POST">
                                            <input type="email" className="form__field"
                                                   placeholder="E-Mail" name="email" required/>
                                            <input type="hidden" name="_captcha" value="false"/>
                                            <input type="hidden" name="_next" value="https://bujo.vn/#/thank"/>
                                            <button type="submit"
                                                    className="btn "><i className="fa fa-arrow-right"></i>
                                            </button>
                                        </form>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <h2 className="mt-5">Tải về</h2>
                    <h5 className="mt-2">Ứng dụng đã có trên cả 2 nền tảng Apple Store và PlayStore</h5>
                    <div className="download-area mt-2">

                        <div className="justify-content-center">
                            <div className="download-button mr-2">
                                <a href="https://apps.apple.com/us/app/bujo/id1577352348">
                                    <img src={btnApp} alt=""/>
                                </a>

                            </div>
                            <div className="download-button ml-2">
                                <a href="https://play.google.com/store/apps/details?id=vn.vuongsoft.bujo">
                                    <img src={btnPlay} alt=""/>
                                </a>
                            </div>
                        </div>

                    </div>
                    <div className="container-fluid px-5 mt-5">

                        <div className="row">
                            <div className="col-4">
                                <NumberCounter end={80} delay={2} className="increment text-center" postFix="%"/>
                                <h4 className="text-center">Tìm được Tasker hài lòng</h4>
                            </div>
                            <div className="col-4">
                                <NumberCounter end={1200} delay={0} className="increment text-center" postFix="+"/>
                                <h4 className="text-center">Công việc được hoàn thành</h4>
                            </div>
                            <div className="col-4">
                                <NumberCounter end={1000} delay={2} className="increment text-center" postFix="+"/>
                                <h4 className="text-center">Giờ làm việc</h4>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        )

    }
}

export default SlideComp;