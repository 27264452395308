import {useState} from "react";

function MenuIcon () {
    const [Menu, setMenu] = useState(true)
    const changeMenu = () => {
        if (window.scrollY >= 600) {
            setMenu(false)
        } else {
            setMenu(true)
        }
    }
    window.addEventListener('scroll', changeMenu)
    return (
        <div className={Menu ? "menu-icon is-hidden" : "menu-icon"}>
            <a href="https://stackoverflow.com/questions/54519654/how-do-i-add-color-to-my-svg-image-in-react">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M17.5 35C7.835 35 0 27.165 0 17.5C0 7.835 7.835 0 17.5 0C27.165 0 35 7.835 35 17.5C35 27.165 27.165 35 17.5 35ZM19.142 12.588C19.142 11.734 19.226 11.277 20.504 11.277H22.209V8.004H19.479C16.2 8.004 15.046 9.592 15.046 12.261V14.226H13.001V17.498H15.046V26.993H19.137V17.498H21.867L22.229 14.226H19.137L19.142 12.588Z"
                          fill= "#9FA3A7"/>
                </svg>
            </a>
            <a href="https://www.facebook.com/">
                <svg width="35" height="37" viewBox="0 0 35 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M17.494 36.007C7.84995 36.007 0.00195312 28.153 0.00195312 18.498C0.00195312 8.84703 7.84995 0.991028 17.494 0.991028C27.141 0.991028 34.99 8.84703 34.99 18.498C34.99 28.153 27.141 36.007 17.494 36.007ZM27.445 16.456V12.393C27.445 10.268 25.721 8.54303 23.598 8.54303H11.391C9.27095 8.54303 7.54695 10.268 7.54695 12.393V24.61C7.54695 26.731 9.27095 28.456 11.391 28.456H23.598C25.721 28.456 27.445 26.731 27.445 24.61V16.456ZM23.598 26.515H11.391C10.337 26.515 9.48495 25.663 9.48495 24.61V16.456H12.454C12.199 17.086 12.055 17.778 12.055 18.498C12.055 21.5 14.494 23.945 17.494 23.945C20.498 23.945 22.937 21.5 22.937 18.498C22.937 17.778 22.79 17.086 22.535 16.456H25.504V24.61C25.504 25.663 24.651 26.515 23.598 26.515ZM21.768 10.847L24.703 10.836H25.143V14.211L21.781 14.221L21.768 10.847ZM17.494 22.003C15.564 22.003 13.992 20.433 13.992 18.498C13.995 17.736 14.239 17.032 14.656 16.456C15.292 15.571 16.328 14.996 17.494 14.996C18.664 14.996 19.7 15.574 20.336 16.456C20.749 17.032 20.997 17.736 20.997 18.498C20.997 20.433 19.424 22.003 17.494 22.003Z"
                          fill="#9FA3A7"/>
                </svg>
            </a>
            <a href="https://dribbble.com/search/3d">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M17.5 35C7.835 35 0 27.165 0 17.5C0 7.835 7.835 0 17.5 0C27.165 0 35 7.835 35 17.5C35 27.165 27.165 35 17.5 35ZM9.211 26.017H13.071V15.195H9.211V26.017ZM11.167 9.98C9.846 9.98 8.983 10.787 8.983 11.849C8.983 12.888 9.821 13.719 11.116 13.719H11.141C12.487 13.719 13.325 12.888 13.325 11.849C13.3 10.787 12.487 9.98 11.167 9.98ZM26.989 19.813C26.989 16.488 25.084 14.941 22.546 14.941C20.495 14.941 19.58 15.991 19.067 16.729V15.195H15.206C15.257 16.212 15.206 26.017 15.206 26.017H19.067V19.974C19.067 19.651 19.092 19.327 19.194 19.097C19.472 18.45 20.108 17.781 21.174 17.781C22.571 17.781 23.13 18.774 23.13 20.229V26.017H26.989V19.813ZM19.041 16.765C19.049 16.753 19.059 16.74 19.067 16.729V16.765H19.041Z"
                          fill="#9FA3A7"/>
                </svg>
            </a>
        </div>
    )
}



export default MenuIcon