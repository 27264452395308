import {Component} from "react";
import {PriccingArea,DetailPlan} from "./Pre/PreComp";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import {NewsArea} from "./News/News";

class Priccing extends Component{
    render() {
        return(
            <div className="m-top">
                <ScrollToTopOnMount />
                <DetailPlan/>
                <PriccingArea/>
            </div>
        )
    }
}
export default Priccing