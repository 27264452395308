import {MenuItems, SupportItems, ContactItem} from "./NavBar/MenuItems";
import {Link} from "react-router-dom";

const Footer = () => <footer className="mt-5">
    <div className="container-fluid  p-5">
        <div className="container">
            <div className="row">
                <div className="col-md-3 col-12">
                    <div className="navbar-brand">bujo</div>
                    <h5>Việc trong ngày, tiền trao liền tay</h5>
                </div>


                <div className="col-md-3 col-12 ">
                    <h4>Liên kết</h4>
                    <ul className="">
                        {MenuItems.map((item, index) => {
                            return (
                                <li key={index}>
                                    <Link to={item.linkTo} className={item.cName}>
                                        {item.title}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="col-md-3 col-12">
                    <h4>Hỗ trợ</h4>
                    <ul className="">
                        {SupportItems.map((item, index) => {
                            return (
                                <li key={index}>
                                    <Link to={item.linkTo} className={item.cName}>
                                        {item.title}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="col-md-3 col-12">
                    <h4>Liên hệ</h4>
                    <ul className="">
                        {ContactItem.map((item, index) => {
                            return (
                                <li key={index}>
                                    <span>
                                         {item.title}
                                    </span>

                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div className="contact">
                <div className="row">
                    <div className=" col-12 d-flex align-text-bottom">

                        <div className="contact-text">
                            <Link to="/Privacy">
                                Quyền riêng tư
                            </Link>

                        </div>
                        <div className="contact-text">
                            <Link to="/Term">
                                Điều khoản
                            </Link>
                        </div>
                        <div className="contact-text">
                            <a href="https://www.facebook.com/bujoapp">
                                <i className="fa fa-facebook-square" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="contact-text">
                            <a href="tel:+84904820785">
                                <i className="fa fa-youtube" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="contact-text">
                            <a href="tel:+84904820785">
                                <i className="fa fa-phone" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</footer>
export default Footer;