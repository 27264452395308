import {Component} from "react";
import {FeatureItem} from "../NavBar/MenuItems";
class ReasonComp extends Component {
    render() {
        return (
            <div className='m-top'>
                <div className="container reason-container">
                    <h2 className="px-5">Tại sao lại là Bujo ?</h2>
                    <div className="row">
                        {FeatureItem.map((item,index)=>{
                            return  <div className="col-md-6 col-12 super-center m-top " key={index}>
                                <img src={require('../../img/gallery/'+item.img+'.png').default} alt="" className="reason-img" />
                                <h3>{item.title}</h3>
                                <h4>
                                    {item.description}
                                </h4>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default ReasonComp;