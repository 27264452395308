import {Component} from "react";
import './FeatureComp.css';
import {Features} from "../NavBar/MenuItems";
import {isElement} from "react-dom/test-utils";

export function FakeComp (prop){

}


class FeatureComp extends Component {
    render() {
        return (
            <div className="m-top">
                <div className="m-top">
                    <h2>Tính năng nổi bật</h2>
                </div>

                <div className="container m-top">
                    {
                        Features.map((item, index) => {
                            return <div key={index}>
                                <section className="feature-section f-s-1 d-flex align-items-center mb-5">
                                    <div className="row">
                                        <div className="col-md-6 col-12 d-flex justify-content-center  ">
                                            <img src={require('../../img/gallery/'+item.img+'.png').default}  alt=""/>
                                        </div>
                                        <div
                                            className="col-md-6 col-12 d-flex justify-content-center align-items-center ">
                                            <div className="feature-content">
                                                <div className="feature-title">
                                                    {item.title}
                                                </div>
                                                <div className="feature-text">
                                                    {item.shortContent}
                                                </div>
                                                <button className="btn" data-toggle="modal" data-target={"#"+item.id}>
                                                    Đọc thêm
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div>
                                    <div className="modal fade" id={item.id} tabIndex={-1} role="dialog"
                                         aria-labelledby="myModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-lg" role="document">
                                            {/*Content*/}
                                            <div className="modal-content">
                                                {/*Body*/}
                                                <div className="modal-body mb-0 p-0">
                                                    <div className=" z-depth-1-half">
                                                        <img src={require('../../img/gallery/'+item.img+'.png').default}  alt=""/>
                                                    </div>
                                                </div>
                                                {/*Footer*/}
                                                <div className="justify-content-start p-5 ">
                                                    <h3>{item.title}</h3>
                                                    <div className="line-wrap my-3"></div>
                                                    <h5 className="ml-3">
                                                        {item.longContent}
                                                    </h5>
                                                    <div>

                                                    </div>
                                                    <button type="button"
                                                            className="align-items-end btn btn-outline-primary btn-rounded btn-md"
                                                            data-dismiss="modal">Đóng
                                                    </button>
                                                </div>
                                            </div>
                                            {/*/.Content*/}
                                        </div>
                                    </div>
                                    {/*Modal: modalYT*/}
                                </div>
                            </div>
                        })
                    }

                </div>
            </div>
        )
    }
}

export default FeatureComp