import {Component} from "react";
import './PreComp.css';
import {NewsItem} from "../NavBar/MenuItems";

const price = [
    {
        title: '1 Tháng',
        price: '100k'
    },
    {
        title: '3 Tháng',
        price: '250k'
    },
    {
        title: '6 Tháng',
        price: '500k'
    }
];
const plan = [
    {
        title: "Offer",
        description: "Thương lượng với người khác",
        freeUser: "3 lần/ngày",
        freeIcon: " ",
        preUser: "Không giới hạn",
        preIcon: " "
    },
    {
        title: "Đăng bài",
        description: "Đăng các bài viết về việc làm của bạn",
        freeUser: "3 lần/ngày",
        freeIcon: " ",
        preUser: "Không giới hạn",
        preIcon: " "
    },
    {
        title: "Nhận việc",
        description: "Nhận việc làm",
        freeUser: "1 lần/ngày",
        freeIcon: " ",
        preUser: "Không giới hạn",
        preIcon: " "
    },
    {
        title: "Xem Offer",
        description: "Xem giá thương lượng của người khác",
        freeUser: "",
        freeIcon: "fa fa-minus",
        preUser: "",
        preIcon: "fa fa-check-circle-o"
    },
    {
        title: "Quảng cáo",
        description: "Không có quảng cáo",
        freeUser: "",
        freeIcon: "fa fa-minus",
        preUser: "",
        preIcon: "fa fa-check-circle-o"
    }
];

export function PriccingArea() {
    return (
        <div className=" container-fluid premium-area bg-light m-top">
            <div className="m-top">
                <h2>
                    Người dùng trả phí
                </h2>
                <div className="container">
                    <h4>
                        Tự do offers, Offers không giới hạn, đăng bài không giới hạn, xem danh sách offer của
                        người khác và còn nhiều hơn nữa...

                    </h4>

                </div>
            </div>
            <div className="">
                <div className="bg-light">
                    <div className="container news mt-5">
                        <div className="row">
                            {
                                price.map((item, index) => (
                                    <div className="col-md-4">
                                        <div className="col max-mb-30 aos-init aos-animate" data-aos="fade-up"><a
                                            href="#"
                                            className="icon-box text-center"
                                            data-bg-color="#fff"
                                            style={{backgroundColor: 'rgb(255, 255, 255)'}}
                                            data-abc="true">
                                            <div className="content">
                                                <h3 className="title fz-20">{item.title}</h3>
                                                <div className="desc">
                                                    <h3 >{item.price}</h3>
                                                    <p className="mx-5">Tất cả tính năng</p>
                                                </div>
                                                <a href="#plan-details">
                                                    <button className="btn">Chi tiết</button>
                                                </a>
                                            </div>
                                        </a></div>
                                    </div>
                                ))
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function DetailPlan() {
    return (
        <div className="container m-top">
            <h2>Chi tiết</h2>
            <div className="plan-details mt-4" id="plan-details">
                <div className="row">
                    <div className="col-4" style={{textAlign: "left"}}><span>Tính Năng</span></div>
                    <div className="col-4"><span> Miễn Phí</span></div>
                    <div className="col-4"><span> Trả Phí</span></div>
                </div>
                {plan.map((item, index) => {
                    return (

                        <div className={index % 2 ? "row bg-light" : "row"} key={index}>
                            <div className="col-4" style={{textAlign: "left"}} data-toggle="tooltip" data-placement="top"  title={item.description}>
                                <h5>{item.title}</h5>
                            </div>
                            <div className="col-4">
                                <i className={item.freeIcon}></i>
                                {item.freeUser}
                            </div>
                            <div className="col-4">
                                <i className={item.preIcon}></i>
                                {item.preUser}
                            </div>
                        </div>
                    )
                })}
            </div>
            <h5 className="mt-5">Thanh toán đơn giản trực tiếp qua ứng dụng, tự động gia hạn ...</h5>
        </div>
    )
}

class PreComp extends Component {
    render() {
        return (
            <div>
                <PriccingArea/>
                <DetailPlan/>
            </div>

        )
    }
}

export default PreComp;