export const MenuItems = [
    {
        title: 'Trang chủ',
        url: '#',
        cName: 'nav-links',
        linkTo: '/'
    },
    {
        title: 'Tính năng',
        url: '#',
        cName: 'nav-links',
        linkTo: '/Features'
    }, {
        title: 'Hướng dẫn',
        url: '#',
        cName: 'nav-links',
        linkTo: '/help'
    },
    {
        title: 'Trở thành đối tác',
        url: '#',
        cName: 'nav-links',
        linkTo: '/submit'
    }
]
export const SupportItems = [
    {
        title: 'Tin tức',
        url: '#',
        cName: 'footer-links',
        linkTo: '/News'
    },
    // {
    //     title: 'Mẹo sử dụng',
    //     url: '#',
    //     cName: 'footer-links',
    //     linkTo: '/Tips'
    // },
    {
        title: 'Khiếu nại',
        url: '#',
        cName: 'footer-links',
        linkTo: '/submit'
    },
]
export const ContactItem = [
    {
        title: 'Support@bujo.vn',
        cName: 'px-2',
        cIcon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.87868 5.87868C3 6.75736 3 8.17157 3 11V13C3 15.8284 3 17.2426 3.87868 18.1213C4.75736 19 6.17157 19 9 19H15C17.8284 19 19.2426 19 20.1213 18.1213C21 17.2426 21 15.8284 21 13V11C21 8.17157 21 6.75736 20.1213 5.87868C19.2426 5 17.8284 5 15 5H9C6.17157 5 4.75736 5 3.87868 5.87868ZM6.5547 8.16795C6.09517 7.8616 5.4743 7.98577 5.16795 8.4453C4.8616 8.90483 4.98577 9.5257 5.4453 9.83205L10.8906 13.4622C11.5624 13.9101 12.4376 13.9101 13.1094 13.4622L18.5547 9.83205C19.0142 9.5257 19.1384 8.90483 18.8321 8.4453C18.5257 7.98577 17.9048 7.8616 17.4453 8.16795L12 11.7982L6.5547 8.16795Z"
                  fill="#000000"/>
            \n' +
            '</svg>,
        linkTo: 'mailto:+Support@bujo.vn'
    },
    {
        title: '+84 (0) 904 820 785',
        cName: 'px-2',
        cIcon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.67962 3.32038L7.29289 2.70711C7.68342 2.31658 8.31658 2.31658 8.70711 2.70711L11.2929 5.29289C11.6834 5.68342 11.6834 6.31658 11.2929 6.70711L9.50048 8.49952C9.2016 8.7984 9.1275 9.255 9.31653 9.63307C10.4093 11.8186 12.1814 13.5907 14.3669 14.6835C14.745 14.8725 15.2016 14.7984 15.5005 14.4995L17.2929 12.7071C17.6834 12.3166 18.3166 12.3166 18.7071 12.7071L21.2929 15.2929C21.6834 15.6834 21.6834 16.3166 21.2929 16.7071L20.6796 17.3204C18.5683 19.4317 15.2257 19.6693 12.837 17.8777L11.6286 16.9714C9.88504 15.6638 8.33622 14.115 7.02857 12.3714L6.12226 11.163C4.33072 8.7743 4.56827 5.43173 6.67962 3.32038Z"
                fill="#000000"/>
        </svg>,
        linkTo: 'tel:+84 (0) 904 820 785'
    },
    {
        title: 'Số 10, Trần Phú, Hà Đông, Hà Nội',
        cName: 'px-2',
        cIcon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.398 19.804C13.881 19.0348 19 16.0163 19 11C19 7.13401 15.866 4 12 4C8.13401 4 5 7.13401 5 11C5 16.0163 10.119 19.0348 11.602 19.804C11.8548 19.9351 12.1452 19.9351 12.398 19.804ZM12 14C13.6569 14 15 12.6569 15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14Z"
                  fill="#000000"/>
        </svg>,
        linkTo: 'https://goo.gl/maps/srg5pcUEE3ZghXou6'
    }
]
export const FeatureItem = [
    {
        title: "Quản lý dễ dàng",
        img: "12",
        description: "Hiển thị trực quan, dễ dàng"
    },
    {
        title: "Tìm người nhanh chóng",
        img: "11",
        description: "Nhận thông báo với thời gian thực"
    },
    {
        title: "Dễ dàng đăng ký",
        img: "13",
        description: "Chỉ yêu cầu số điện thoại để đăng ký"
    },
    {
        title: "Phản ánh chất lượng",
        img: "14",
        description: "Phản ánh chât lượng của Tasker"
    }
];

export const HelpItem = [
    {
        title: "Đăng bài",
        img: "post",
        content: "Khi bạn cần người làm một việc gì đó, bạn có thể truy cập ứng dụng Bujo, tìm một loại công việc phù hợp, điền đủ thông tin, sau đó chỉ cần đợi vài phút, những người làm ở xung quanh bạn sẽ tự động được ứng dụng gợi ý, họ sẽ trả giá với bạn, chi tiết sẽ có thể được thấy ở trang quản lý, sau đó bạn có thể chọn người làm phù hợp, ứng dụng sẽ hiển thị thông tin liên lạc của cả 2 bên, sau khi làm xong việc, bạn hãy nhớ đánh giá người làm nhé !"
    },
    {
        title: "Nhận việc",
        img: "find",
        content: "Ứng dụng có chức năng gợi ý các công việc phù hợp ở gần bạn(đề được gợi ý chính xác, bạn nhớ điền đầy đủ thông tin nhé), bạn có thể chọn lựa công việc, bấm vào từng mục để xem chi tiết công việc, nếu chọn được công việc phù hợp, bạn có thể trả giá với người đăng, sau khi trả giá, hãy đợi 1 vài phút để người đăng chấp nhận deal của bạn. Sau khi được chấp nhận, ứng dụng sẽ hiển thị thông tin liên lạc của 2 bên, 2 bên sẽ tự liên lạc với nhau và làm việc, Bujo sẽ không can thiệp vào quá trình làm việc, sau khi làm việc xong, bạn hãy nhắc nhở người đắng đánh giá thật tốt cho bạn nhé !"
    },
    {
        title: null,
        img: null,
        content: "Bujo hướng đến mọi đối tượng trong độ tuổi lao động, từ sinh viên đến lao động tự do. Chúng tôi hiểu rằng việc tạo ra một ứng dụng thân thiện với người dùng là vô cùng quan trọng. Vì vậy, ứng dụng việc làm phát sinh Bujo đã hoàn toàn sở hữu những ưu điểm này. \n" +
            "Tất cả các mục trong ứng dụng Bujo đều được hiển thị đầy đủ tại trang Home. Không những thế, các icon được gọi tên theo từng chức năng của chúng, giúp người dùng dễ dàng thực hiện các thao tác đăng việc, quản lí công việc, tìm việc, quản lí tài khoản của họ. Ngoài ra, tính năng lọc công việc tại mục tìm việc cũng được xem là một trong số những tính năng nổi bật của Bujo. Bạn hoàn toàn có thể tìm kiếm các công việc dựa theo loại công việc, khoảng cách, mức giá mà bạn mong muốn. \n" +
            "Với một giao diện thân thiện với người dùng như thế, Bujo tin chắc rằng sẽ có thể làm hài lòng mọi đối tượng người dùng, để những người đang tìm kiếm công việc phát sinh ở mọi độ tuổi, mọi tầng lớp đều có thể sử dụng ứng dụng một cách dễ dàng nhất!"

    }
]
export const QuestionItem = [
    {
        title: "1. Bujo cung cấp dịch vụ ở khu vực nào ?",
        content: "  Hiện nay các Bujoer đã có thể đăng công việc tại 63 tỉnh thành. Ngoài ra chức năng \n" +
            "gợi ý người làm đang hỗ trợ tại 3 tỉnh thành lớn: Hà Nội, Thành Phố Hồ Chí Minh và Đà Nẵng."
    },
    {
        title: "2. Đăng tin như thế nào ?",
        content: " Nếu bạn có việc phát sinh cần giải quyết, bạn chỉ cần mở ứng dụng Bujo và vào\n" +
            "mục đăng công việc. Điền đầy đủ các thông tin liên quan đến công việc bạn đăng như tiêu đề, yêu cầu, chi tiết công việc, hình ảnh, minh họa, giá cả, thời gian, địa điểm.\n" +
            "Việc còn lại hãy để Bujo lo"
    },
    {
        title: "3. Làm sao để thu hút được nhiều người nhận việc ?",
        content: " Điền đầy đủ thông tin cá nhân, xác thực tài khoản để tăng độ uy tín sẽ giúp bạn thu hút được nhiều người nhận việc hơn. Ngoài ra\n" +
            "công việc  đăng càng chi tiết rõ ràng thì sẽ càng tạo nên độ tin cậy cho công việc của bạn."
    },
    {
        title: "4. Làm sao để chọn người làm thích hợp nhất",
        content: " Đầu tiên hãy chú ý để thông tin cá nhân của người nhận, một tài khoản với đầy đủ thông\n" +
            "tin và xác thực sẽ đảm bảo hơn cho bản thân và công việc của bạn. \n" +
            "Sau đó hãy chú ý đến số điểm của người đó, điểm số càng cao chứng tỏ rằng người đó làm việc càng tốt!"
    }, {
        title: "5. Tôi có thể thanh toán qua đâu, có thể thanh toán trực tiếp ở văn phòng Bujo không",
        content: "Hiện tại Bujo chưa support tại văn phòng. Các bạn có thể thanh toán qua ví điện tử\n" +
            "momo , zalo , thẻ nội địa,...do bên thứ 3 của Bujo cung cấp."
    }, {
        title: "6. Tại sao đôi lại phải nâng cấp tài khoản, lợi ích là gì",
        content: " Bujo hỗ trợ người dùng nâng cấp tài khoản bằng cách trở thành người dùng trả phí. Các gói thành viên có mức giá phù hợp với nhiều đối tượng và nhu cầu tìm việc của mọi người dùng.\n" +
            "\n" +
            "Khi nâng cấp tài khoản bạn hoàn toàn có thể đăng bài và nhận việc không giới hạn. Tích điểm đổi thưởng, trở thành thành viên bạc, vàng,... Và đặc biệt bạn còn có thể dùng số điểm thưởng đó để quy ra voucher hoặc tiền mặt cho phù hợp với nhu cầu sử dụng. Ngoài ra, thành viên trả phí có thể xem được các mức giá của người nhận việc khác và đưa ra mức giá cạnh tranh nhất để giành được công việc, thay vì chỉ đưa ra được 1 mức giá tự do như thành viên không trả phí. Việc nâng cấp tài khoản còn giúp bạn hoàn toàn không bị gián đoạn quá trình sử dụng ứng dụng Bujo bởi những quảng cáo của bên thứ 3 cung cấp. Còn chần chừ gì mà không tải ứng dụng và đăng kí trở thành thành viên trả phí ngay hôm nay để được những trải nghiệm tốt nhất trên ứng dụng Bujo và tìm kiếm thêm nguồn thu nhập cho bản thân!"
    }
]


export const Features = [
    {
        id: "1001",
        title: "  Dễ dàng đăng tin",
        img: "Rectangle 373",
        video: "https://www.youtube.com/watch?v=a0qC7lG3Vfc&ab_channel=Myynhh",
        shortContent: "   Đăng tin tìm việc theo hạng mục công việc, chỉ qua vài thao tác đơn giản như mô\n" +
            "                                        tả công việc, thời gian, địa điểm làm việc,..Hoàn tất đăng bài chỉ qua 1 nút\n" +
            "                                        bấm.",
        longContent: "Bujo giúp cho việc đăng tin tìm kiếm công việc trở nên dễ dàng hơn bao giờ\n" +
            "                                            hết. Bạn chỉ cần chọn hạng mục công việc muốn đăng bằng cách bấm vào biểu\n" +
            "                                            tượng tương ứng trong mục đăng việc như: dọn dẹp, chuyển nhà, sửa chữa, vận\n" +
            "                                            chuyển,...\n" +
            "                                            Thực hiện các thao tác điền thông tin và hình ảnh minh họa về công việc bạn\n" +
            "                                            đang cần tìm người làm. Điền đầy đủ và mô tả chi tiết về công việc sẽ giúp\n" +
            "                                            tăng độ tin cậy cho công việc của bạn và thu hút được nhiều người làm việc\n" +
            "                                            thực hiện thương lượng về giá cho công việc đó hơn.\n" +
            "                                            Hoàn thành thao tác đăng việc chỉ qua nút “Đăng”, sau đó việc của bạn chỉ là\n" +
            "                                            chờ đợi người làm trả giá và lựa chọn người làm phù hợp nhất để thực hiện\n" +
            "                                            công việc đó."
    },
    {
        id: "1002",
        title: "Dễ dàng sử dụng",
        img: "Group 772",
        video: "https://youtu.be/1f5rxh0cJ9E",
        shortContent: "   Đăng tin tìm việc theo hạng mục công việc, chỉ qua vài thao tác đơn giản như mô\n" +
            "                                        tả công việc, thời gian, địa điểm làm việc,..Hoàn tất đăng bài chỉ qua 1 nút\n" +
            "                                        bấm.",
        longContent: "   Đăng tin tìm việc theo hạng mục công việc, chỉ qua vài thao tác đơn giản như mô\n" +
            "                                        tả công việc, thời gian, địa điểm làm việc,..Hoàn tất đăng bài chỉ qua 1 nút\n" +
            "                                        bấm."
    },
    {
        id: "1003",
        title: "Bảo mật và uy tín",
        img: "iphone-12-mini--white",
        video: "https://www.youtube.com/watch?v=XBKzpTz65Io&ab_channel=TheFlutterWay",
        shortContent: "Cam kết bảo mật thông tin khách hàng khi đăng kí thành viên và thực kiện các giao dịch trên nền tảng. Cung cấp đầy đủ bằng cấp, chứng chỉ dành cho các công việc yêu cầu chuyên môn.",
        longContent: "Bảo mật dữ liệu là vấn đề của sự tin tưởng. Bujo.vn nhận thức được rằng việc bảo mật thông tin & danh tính của quý khách đặc biệt quan trọng. Vì vậy, chúng tôi cam kết sử dụng những thông tin mà quý khách cung cấp theo cách được đề ra trong Chính sách điều khoản.\n" +
            "Chúng tôi sẽ thu thập những thông tin cần thiết và có liên quan đến giao dịch giữa chúng tôi và người dùng.\n" +
            "Chúng tôi sử dụng công nghệ theo dấu khác để thu thập một số thông tin khi người dùng tương tác trên website & ứng dụng Bujo.vn cho mục đích quảng cáo & chính sửa sản phẩm để nâng cao trải nghiệm.\n" +
            "Chúng tôi cam kết giữ thông tin của quý khách trong thời gian luật pháp yêu cầu hoặc cho mục đích mà thông tin đó được thu thập."
    },
]