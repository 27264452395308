import ScrollToTopOnMount from "../ScrollToTopOnMount";

const Privacy = () => {
    return <div>
        <ScrollToTopOnMount />
        <div className="container m-top csdk-pd">
            <ul>
                <h2 className="mb-5">Ch&iacute;nh s&aacute;ch quyền ri&ecirc;ng tư</h2>
                <li><strong>Ch&iacute;nh s&aacute;ch quyền ri&ecirc;ng tư</strong></li>
            </ul>
            <p>Bujo.vn nhận thức được rằng việc bảo mật th&ocirc;ng tin &amp; danh t&iacute;nh của
                qu&yacute; kh&aacute;ch đặc biệt quan trọng. V&igrave; vậy, ch&uacute;ng t&ocirc;i cam kết sử dụng những
                th&ocirc;ng tin m&agrave; qu&yacute; kh&aacute;ch cung cấp theo c&aacute;ch được đề ra trong
                Ch&iacute;nh s&aacute;ch Bảo mật n&agrave;y.</p>
            <p>Ch&uacute;ng t&ocirc;i sẽ thu thập những th&ocirc;ng tin cần thiết v&agrave; c&oacute; li&ecirc;n quan
                đến giao dịch giữa ch&uacute;ng t&ocirc;i v&agrave; qu&yacute; kh&aacute;ch.</p>
            <p>Ch&uacute;ng t&ocirc;i sử dụng cookies v&agrave; c&ocirc;ng nghệ theo dấu kh&aacute;c để thu thập một số
                th&ocirc;ng tin khi qu&yacute; kh&aacute;ch tương t&aacute;c tr&ecirc;n website &amp; ứng dụng Bujo.vn
                cho mục đ&iacute;ch quảng c&aacute;o &amp; ch&iacute;nh sửa sản phẩm để n&acirc;ng cao trải nghiệm.</p>
            <p>Ch&uacute;ng t&ocirc;i cam kết giữ th&ocirc;ng tin của qu&yacute; kh&aacute;ch trong thời gian luật
                ph&aacute;p y&ecirc;u cầu hoặc cho mục đ&iacute;ch m&agrave; th&ocirc;ng tin đ&oacute; được thu
                thập.</p>
            <p>Qu&yacute; kh&aacute;ch c&oacute; thể gh&eacute; thăm website m&agrave; kh&ocirc;ng cần phải cung cấp bất
                kỳ th&ocirc;ng tin c&aacute; nh&acirc;n n&agrave;o.&nbsp;</p>
            <ol>
                <li>Mục đ&iacute;ch &amp; phạm vi thu thập th&ocirc;ng tin Kh&aacute;ch h&agrave;ng</li>
            </ol>
            <p><em>1.1 Khi qu&yacute; kh&aacute;ch đăng k&yacute; t&agrave;i khoản hoặc sử dụng dịch vụ tại ứng dụng
                Bujo.vn, th&ocirc;ng tin c&aacute; nh&acirc;n m&agrave; ch&uacute;ng t&ocirc;i thu thập bao gồm:</em>
            </p>
            <ul>
                <li>Họ &amp; T&ecirc;n Kh&aacute;ch h&agrave;ng.</li>
                <li>Địa chỉ sử dụng dịch vụ.</li>
                <li>Địa chỉ Email&nbsp;</li>
                <li>Số điện thoại li&ecirc;n hệ hoặc địa chỉ Facebook Kh&aacute;ch h&agrave;ng.</li>
            </ul>
            <p><em>1.2 Những th&ocirc;ng tin tr&ecirc;n sẽ được sử dụng cho một hoặc tất cả c&aacute;c mục đ&iacute;ch
                sau đ&acirc;y:</em></p>
            <ul>
                <li>Li&ecirc;n hệ x&aacute;c nhận khi qu&yacute; kh&aacute;ch đặt dịch vụ.</li>
                <li>Cung cấp th&ocirc;ng tin li&ecirc;n quan đến c&aacute;c dịch vụ do Bujo.vn cung cấp &amp; c&aacute;c
                    ch&iacute;nh s&aacute;ch hỗ trợ của Bujo.vn.
                </li>
                <li>Xử l&yacute; c&aacute;c y&ecirc;u cầu cung cấp dịch vụ v&agrave; th&ocirc;ng tin qua ứng
                    dụng &amp; website của ch&uacute;ng t&ocirc;i theo y&ecirc;u cầu của qu&yacute; kh&aacute;ch.
                </li>
                <li>Ngo&agrave;i ra, ch&uacute;ng t&ocirc;i sẽ sử dụng th&ocirc;ng tin qu&yacute; kh&aacute;ch cung cấp
                    để hỗ trợ quản l&yacute; t&agrave;i khoản Kh&aacute;ch h&agrave;ng; x&aacute;c nhận v&agrave; thực
                    hiện c&aacute;c giao dịch t&agrave;i ch&iacute;nh li&ecirc;n quan đến c&aacute;c khoản thanh
                    to&aacute;n trực tuyến của qu&yacute; kh&aacute;ch qua ứng dụng; kiểm tra dữ liệu tải từ website của
                    ch&uacute;ng t&ocirc;i; cải thiện giao diện hoặc nội dung của c&aacute;c trang mục tr&ecirc;n ứng
                    dụng &amp; website để Kh&aacute;ch h&agrave;ng dễ d&agrave;ng hơn khi sử dụng; nhận diện
                    kh&aacute;ch đến thăm website &amp; ứng dụng; nghi&ecirc;n cứu về nh&acirc;n khẩu học của người
                    d&ugrave;ng; gửi đến qu&yacute; kh&aacute;ch th&ocirc;ng tin m&agrave; ch&uacute;ng t&ocirc;i nghĩ
                    sẽ c&oacute; &iacute;ch hoặc do qu&yacute; kh&aacute;ch y&ecirc;u cầu, bao gồm th&ocirc;ng tin về
                    sản phẩm v&agrave; dịch vụ, với điều kiện qu&yacute; kh&aacute;ch đồng &yacute; kh&ocirc;ng phản đối
                    việc được li&ecirc;n lạc cho c&aacute;c mục đ&iacute;ch tr&ecirc;n.
                </li>
            </ul>
            <p><em>1.3 Phạm vi sử dụng th&ocirc;ng tin Kh&aacute;ch h&agrave;ng:</em></p>
            <ul>
                <li>Chia sẻ th&ocirc;ng tin của qu&yacute; kh&aacute;ch cho c&aacute;c đối t&aacute;c thanh to&aacute;n
                    trực tuyến của ch&uacute;ng t&ocirc;i để c&oacute; thể thực hiện dịch vụ cho
                    qu&yacute; kh&aacute;ch. Danh s&aacute;ch c&aacute;c đơn vị gồm: VNPay, V&iacute; Điện Tử MoMo,
                    Zalopay, Thẻ t&iacute;n dụng, thẻ nội địa.
                </li>
                <li>Gửi c&aacute;c th&ocirc;ng tin khuyến m&atilde;i/quảng c&aacute;o, cung cấp c&aacute;c dịch vụ
                    tiện &iacute;ch đến qu&yacute; kh&aacute;ch. Qu&yacute; kh&aacute;ch c&oacute; thể hủy nhận
                    c&aacute;c th&ocirc;ng tin đ&oacute; bất kỳ l&uacute;c n&agrave;o bằng c&aacute;ch sử dụng chức năng
                    hủy đăng k&yacute; trong c&aacute;c th&ocirc;ng b&aacute;o quảng c&aacute;o.
                </li>
                <li>Li&ecirc;n lạc với qu&yacute; kh&aacute;ch v&agrave; giải quyết trong c&aacute;c trường hợp đặc
                    biệt.&nbsp;</li>
                <li>Chi tiết đặt dịch vụ của qu&yacute; kh&aacute;ch sẽ được ch&uacute;ng t&ocirc;i lưu trữ nhưng
                    v&igrave; l&yacute; do bảo mật, qu&yacute; kh&aacute;ch kh&ocirc;ng thể y&ecirc;u cầu ch&uacute;ng
                    t&ocirc;i cung cấp lại những th&ocirc;ng tin đ&oacute;. Tuy nhi&ecirc;n, qu&yacute; kh&aacute;ch
                    c&oacute; thể kiểm tra th&ocirc;ng tin bằng c&aacute;ch đăng nhập v&agrave;o t&agrave;i khoản của
                    m&igrave;nh tr&ecirc;n ứng dụng Bujo. Qu&yacute; kh&aacute;ch c&oacute; thể theo d&otilde;i chi tiết
                    những c&ocirc;ng việc đ&atilde; ho&agrave;n tất, c&ocirc;ng việc mở v&agrave; c&ocirc;ng việc sắp
                    hoặc đang được thực hiện; đồng thời quản l&yacute; &amp; thay đổi th&ocirc;ng tin
                    c&aacute; nh&acirc;n...
                </li>
            </ul>
            <ol start="2">
                <li>Cập nhật thay đổi th&ocirc;ng tin c&aacute; nh&acirc;n</li>
            </ol>
            <p>Qu&yacute; kh&aacute;ch c&oacute; thể cập nhật th&ocirc;ng tin c&aacute; nh&acirc;n của m&igrave;nh bất
                kỳ l&uacute;c n&agrave;o bằng c&aacute;ch đăng nhập v&agrave;o ứng dụng Bujo.vn v&agrave; h&atilde;y
                b&aacute;o cho ch&uacute;ng t&ocirc;i biết sự thay đổi để hỗ trợ qu&yacute; kh&aacute;ch được tốt nhất
                trong qu&aacute; tr&igrave;nh hậu m&atilde;i.</p>
            <p>Qu&yacute; kh&aacute;ch cần bảo đảm l&agrave; th&ocirc;ng tin được truy cập một c&aacute;ch b&iacute; mật
                v&agrave; kh&ocirc;ng l&agrave;m lộ cho một b&ecirc;n thứ ba kh&aacute;c kh&ocirc;ng c&oacute; quyền.
                Ch&uacute;ng t&ocirc;i sẽ kh&ocirc;ng chịu tr&aacute;ch nhiệm đối với việc sử dụng sai mật khẩu trừ khi
                đ&oacute; l&agrave; lỗi của ch&uacute;ng t&ocirc;i.</p>
            <ol start="3">
                <li>Bảo vệ th&ocirc;ng tin Kh&aacute;ch h&agrave;ng</li>
            </ol>
            <p>Bujo.vn đảm bảo rằng mọi th&ocirc;ng tin thu thập được sẽ được lưu giữ an to&agrave;n. Ch&uacute;ng
                t&ocirc;i bảo vệ th&ocirc;ng tin c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch bằng
                c&aacute;ch:&nbsp;</p>
            <ul>
                <li>Giới hạn truy cập th&ocirc;ng tin c&aacute; nh&acirc;n.</li>
                <li>Sử dụng sản phẩm c&ocirc;ng nghệ bảo mật cao nhất để ngăn chặn truy cập hệ thống quản l&yacute; dữ
                    liệu nội bộ tr&aacute;i ph&eacute;p.
                </li>
                <li>X&oacute;a mọi th&ocirc;ng tin c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch khi những
                    th&ocirc;ng tin đ&oacute; kh&ocirc;ng c&ograve;n cần thiết cho mục đ&iacute;ch lưu trữ dữ liệu của
                    ch&uacute;ng t&ocirc;i.&nbsp;</li>
            </ul>
            <ol start="4">
                <li>Ti&ecirc;u chuẩn bảo mật với giao dịch thanh to&aacute;n điện tử</li>
            </ol>
            <p><em>4.1 C&aacute;c giao dịch thanh to&aacute;n bằng thẻ quốc tế v&agrave; thẻ nội địa (internet banking)
                đảm bảo tu&acirc;n thủ c&aacute;c ti&ecirc;u chuẩn bảo mật của c&aacute;c Đối T&aacute;c Cổng Thanh
                To&aacute;n, gồm:</em></p>
            <ul>
                <li>Th&ocirc;ng tin t&agrave;i ch&iacute;nh của Kh&aacute;ch h&agrave;ng sẽ được bảo vệ trong suốt
                    qu&aacute; tr&igrave;nh giao dịch bằng giao thức SSL (Secure Sockets Layer).
                </li>
                <li>Chứng nhận ti&ecirc;u chuẩn bảo mật dữ liệu th&ocirc;ng tin thanh to&aacute;n (PCI DSS) do Trustwave
                    cung cấp.
                </li>
                <li>Mật khẩu sử dụng một lần (OTP) được gửi qua SMS để đảm bảo việc truy cập t&agrave;i khoản được
                    x&aacute;c thực.
                </li>
                <li>Ti&ecirc;u chuẩn m&atilde; h&oacute;a SHA 512 bit.</li>
                <li>C&aacute;c nguy&ecirc;n tắc v&agrave; quy định bảo mật th&ocirc;ng tin trong ng&agrave;nh t&agrave;i
                    ch&iacute;nh ng&acirc;n h&agrave;ng theo quy định của Ng&acirc;n h&agrave;ng nh&agrave; nước Việt
                    Nam.&nbsp;</li>
            </ul>
            <p><em>4.2 Ch&iacute;nh s&aacute;ch bảo mật giao dịch trong thanh to&aacute;n của Bujo.vn &aacute;p dụng với
                Kh&aacute;ch h&agrave;ng:</em></p>
            <p>Bujo.vn cam kết đảm bảo thực hiện nghi&ecirc;m t&uacute;c c&aacute;c biện ph&aacute;p bảo mật cần thiết
                cho mọi hoạt động thanh to&aacute;n thực hiện tại ứng dụng Bujo.vn</p>
            <ol start="5">
                <li>Cam kết của Bujo.vn</li>
            </ol>
            <p>Ch&uacute;ng t&ocirc;i sẽ kh&ocirc;ng chia sẻ hoặc tiết lộ th&ocirc;ng tin của qu&yacute; kh&aacute;ch
                cho bất kỳ một tổ chức/c&aacute; nh&acirc;n n&agrave;o kh&aacute;c ngoại trừ những đối t&aacute;c
                v&agrave; c&aacute;c b&ecirc;n thứ ba c&oacute; li&ecirc;n quan trực tiếp đến việc giao dịch trực tuyến
                hoặc thực hiện dịch vụ m&agrave; qu&yacute; kh&aacute;ch đ&atilde; đặt ứng dụng Bujo.</p>
            <p>Trong một v&agrave;i trường hợp đặc biệt, Bujo.vn c&oacute; thể bị y&ecirc;u cầu phải tiết lộ th&ocirc;ng
                tin c&aacute; nh&acirc;n, v&iacute; dụ khi c&oacute; căn cứ cho việc tiết lộ th&ocirc;ng tin
                l&agrave; cần thiết để ngăn chặn c&aacute;c mối đe dọa về t&iacute;nh mạng v&agrave; sức khỏe, hay cho
                mục đ&iacute;ch thực thi ph&aacute;p luật. Bujo.vn cam kết tu&acirc;n thủ Điều Luật Bảo Mật
                v&agrave; c&aacute;c Nguy&ecirc;n tắc Bảo mật Quốc gia.</p>
            <p>Nếu qu&yacute; kh&aacute;ch tin rằng th&ocirc;ng tin của qu&yacute; kh&aacute;ch bị Bujo.vn x&acirc;m
                phạm, xin vui l&ograve;ng li&ecirc;n hệ với ch&uacute;ng t&ocirc;i theo số hotline: <strong><em>0904 820
                    785 </em></strong>để được giải quyết.</p>
            <ol start="6">
                <li>Thay đổi của Ch&iacute;nh s&aacute;ch Bảo mật</li>
            </ol>
            <p>Bujo.vn c&oacute; quyền thay đổi v&agrave; chỉnh sửa Quy định Bảo mật n&agrave;y v&agrave;o bất kỳ
                l&uacute;c n&agrave;o. Bất cứ sự thay đổi n&agrave;o về ch&iacute;nh s&aacute;ch n&agrave;y đều được
                đăng tr&ecirc;n trang website &amp; ứng dụng Bujo.vn của ch&uacute;ng t&ocirc;i.</p>

        </div>

    </div>
}
export default Privacy