import {MenuItems} from "./MenuItems";
import './Navbar.css';
import {useState} from "react";
import {Link} from "react-router-dom";
function Header() {
    const [click, setClick] = useState(false);
    const [navBar, setNavBar] = useState(false)
    const changeNavBar = () => {
        if (window.scrollY >= 80) {
            setNavBar(true)
        } else {
            setNavBar(false)
        }
    }
    const handleClick = () => setClick(!click);
    window.addEventListener('scroll', changeNavBar)
    return (
        <nav className={navBar ? "navbar navbar-expand-md navbar-light bg-light fixed-top":"navbar navbar-expand-md navbar-light bg-light fixed-top bg-trans"}>
            <div className="container-fluid">
                <a className="navbar-brand" href="#">bujo</a>
                <button onClick={handleClick} className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                        aria-label="Toggle navigation" >
                    <i className={click ? 'fa fa-times' : 'fa fa-bars'}></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className={click ? 'nav-menu active' : 'nav-menu'} id="navbar-id">
                        {MenuItems.map((item, index) => {
                            return (
                                <li className="d-flex align-items-center" key={index}>
                                    <Link to={item.linkTo} className={item.cName}>
                                        {item.title}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header;