import './App.css';
import './Main.css';
import {HashRouter, Route, Switch} from "react-router-dom";
import Header from './components/NavBar/Header'
import MenuIcon from './components/NavBar/MenuIcon'
import Home from './Home'
import FeatureComp from "./components/Features/FeatureComp";
import Privacy from "./components/Features/Privacy";
import Submit from "./components/Features/Submit";
import Terms from './components/Terms';
import Help from './components/Help';
import News, {NewsArea} from './components/News/News';
import Priccing from './components/Priccing';
import Footer from './components/Footer';
import Thank from './components/Thank';
import PageNotFound from "./components/PageNotFound";
function App() {
    return (
        <HashRouter>
            <Header/>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/Features" component={FeatureComp}/>
                <Route exact path="/Term" component={Terms}/>
                <Route exact path="/Privacy" component={Privacy}/>
                <Route exact path="/submit" component={Submit}/>
                <Route exact path="/help" component={Help}/>
                <Route exact path="/plan-details" component={Priccing}/>
                <Route exact path="/News/:id" component={NewsArea}/>
                <Route exact path="/News" component={News}/>
                <Route exact path="/thank" component={Thank}/>
                <Route path="*" component={PageNotFound}/>
            </Switch>
            <Footer/>
        </HashRouter>
    );
}

export default App;
