import './TipsArea.css'

function TipsComp(){
    return(
        <div className="m-top">
            <h2>AMD inside</h2>
            <div className="container m-top">
                <div className="row ">
                    <div className="col-md-6 col-12">
                        <div className="card card-01">
                            <div className="card-title mb-5">
                                Mẹo đăng tin tìm người làm
                            </div>
                            <button className="btn-outline">
                                Read
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="card card-02">
                            <div className="card-title mb-5">
                                Mẹo đăng tin tìm người làm
                            </div>
                            <button className="btn-outline">
                                Read
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="card card-03">
                            <div className="card-title mb-5">
                                Mẹo đăng tin tìm người làm
                            </div>
                            <button className="btn-outline">
                                Read
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="card card-04">
                            <div className="card-title mb-5">
                                Mẹo đăng tin tìm người làm
                            </div>
                            <button className="btn-outline">
                                Read
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TipsComp;