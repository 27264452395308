import {Component} from "react";
import SlideComp from './components/slideBg/SlideComp'
import AdsComp from './components/Ads/AdsComp';
import ReasonComp from './components/Reason/ReasonComp'
import FeatureComp from './components/Features/FeatureComp'
import VideoComp from './components/VideoArea/VideoComp';
import TipsComp from './components/TipsArea/TipsComp';
import PreComp from './components/Pre/PreComp';
import DonateComp from './components/Pre/DonateComp';
import ScrollToTopOnMount from "./components/ScrollToTopOnMount";
import News, {NewsArea} from "./components/News/News";
import MenuIcon from "./components/NavBar/MenuIcon";
class Home extends Component {
    render() {
        return (<div>
            <ScrollToTopOnMount />
            <MenuIcon/>
            <SlideComp/>
            <AdsComp/>
            <ReasonComp />
            <FeatureComp/>
            <PreComp />
            {/*<VideoComp/>*/}
            {/*<TipsComp/>*/}
            <DonateComp />
            <News/>
            <div className="m-top"></div>
        </div>)
    }
}

export default Home;